import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { UserIcon } from "../../../../../../components/IconsSVG";
import { ERoles } from "../../../../../../enums/Roles";
import { InexApiService } from "../../../../../../services/InexApiService";
import store from "../../../../../../store/store";
import { ReportDatatable } from "../ReportDatatable";
import { ReportDatatableNotLoaded } from "../ReportDatatableNotLoaded";
import { ReportDatatableNotAllocated } from "../ReportDatatableNotAllocated";
import { StyledAccordion } from "../../../../components/Accordion/styles";
import { CustomAccordionReport } from "../../../../components/AccordionReport";

import { transformMinutesToHours } from "./../../../../../../utils/CalculateSumTime";

export const ReportCard = ({
  key,
  tab,
  cardKey,
  title,
  projectList,
  totalHours,
  qtdHoursNotAllocated,
  totalAllocatedDecimalHours, //Aqui só usamos nas abas: WithoutCoordinatorApproval e
  setLoading,
  setReload,
  handleProjectListUpdate
}) => {
  const api = new InexApiService();
  const { auth } = store.getState();

  const [isAllowed, setIsAllowed] = useState(false);

  const handleAlocRequest = async e => {
    setLoading(true);

    let url = "";
    if (tab === "AlocatorWithoutRequest") {
      url = "/approvalreport/set-allocation-requested-date";
    } else {
      url = "/approvalreport/unset-allocation-requested-date";
    }

    try {
      await api.makeHttpRequest({
        url: url,
        method: "POST",
        data: projectList
      });

      handleProjectListUpdate(cardKey, tab);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao solicitar/rejeitar itens selecionados.",
        "error"
      );
    }
    // here we need reset approvedList, because if we use a new command,
    // that will use the last list in submit.

    setReload(true);
    setLoading(false);
  };

  useEffect(() => {
    setIsAllowed(
      (auth?.user?.role?.type === ERoles.ADMIN ||
        auth?.user?.role?.type === ERoles.OPERATION) ??
        false
    );
  }, [auth]);

  return projectList?.length <= 0 ? null : (
    <>
      <StyledAccordion shadow="true">
        <CustomAccordionReport
          eventKey="0"
          title={title}
          icon={UserIcon}
          totalHours={totalHours ? totalHours : totalAllocatedDecimalHours}
          qtdHoursNotAllocated={qtdHoursNotAllocated}
          isAllowed={isAllowed}
          handleAlocRequest={handleAlocRequest}
          tab={tab}
        >
          {tab === "AlocatorWithoutRequest" || tab === "AlocatorWithRequest" ? (
            <ReportDatatable key={key} projectList={projectList} />
          ) : tab === "notAllocatedByAllocator" ? (
            <ReportDatatableNotAllocated key={key} projectList={projectList} />
          ) : (
            <ReportDatatableNotLoaded key={key} projectList={projectList} />
          )}
        </CustomAccordionReport>
      </StyledAccordion>
    </>
  );
};
