import React, { useState, useEffect, Fragment } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import { InexApiService } from "../../../../services/InexApiService";
import { EndOfPeriodIcon } from "../../../../components/IconsSVG";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { ApprovalCard } from "./components/ApprovalCard";
import Filters from "./components/Filters";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from 'react-router-dom';

const EndOfPeriodFlex = () => {
  const api = new InexApiService();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const idClient = queryParams.get('idClient');
  const idProject = queryParams.get('idProject');
  const user = useSelector(state => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [period, setPeriod] = useState({});
  const [projectListCurrentCycle, setProjectListCurrentCycle] = useState([]);
  const [clientPreSelected, setClientPreSelected] = useState(null);
  const [projectPreSelected, setProjectPreSelected] = useState(null);
  const [flexiClosureDates, setFlexiClosureDates] = useState([]);
  const [lastFilter, setLastFilter] = useState();


  useEffect(() => {
    window.setPageTitle("Fechamento Flexível - Admin");

    const loadSelects = async () => {
      const selects = await api.makeHttpRequest({
        url: "/flexiclosure/selects"
      });

      if (selects && idClient && idProject) {
        const client = selects.clients.find((c) => c.idClient === Number(idClient))
        const project = selects.projects.find((p) => p.idProject === Number(idProject))
        setClientPreSelected(client);
        setProjectPreSelected(project);
      }

      setFilterOptions(selects ?? {});
    };

    loadSelects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (filters) => {
    setLoading(true);
    setLastFilter(filters);

    const updatedFlexiClosureDates = [...flexiClosureDates];

    let hasUpdates = false; // Adicione esta variável para rastrear se houve alterações

    for (const fcd of updatedFlexiClosureDates) {
      const startDate = moment(fcd.startDate).startOf("day").subtract(3, "hour").toDate();
      const endDate = moment(fcd.endDate).endOf("day").subtract(3, "hour").toDate();

      try {
        const params = {
          startPeriod: startDate,
          endPeriod: endDate,
          hasPeriod: true,
          idClient: filters.client,
          idProject: fcd.project.idProject,
          idCoordinator: filters.coordinator,
          idProfessional: filters.employee,
          showOnlyMyProfessionals: filters.showOnlyMyProfessionals,
        };

        const resProjects = await api.makeHttpRequest({
          url: "/flexiclosure",
          params,
        });

        // Verifique se houve alteração no projectCurrentCycles
        if (JSON.stringify(fcd.projectCurrentCycles) !== JSON.stringify(resProjects)) {
          fcd.projectCurrentCycles = resProjects ?? [];
          hasUpdates = true; // Marque como atualizado
        }
      } catch (e) {
        Swal.fire(
          "Erro!",
          e?.response?.data ? e.response.data : "Erro ao buscar os dados.",
          "error"
        );
      }
    }
    // Atualize o estado apenas se houver alterações
    if (hasUpdates) {
      setFlexiClosureDates(updatedFlexiClosureDates);
    }

    setLoading(false);
  };


  useEffect(() => {
    if (
      lastFilter &&
      lastFilter.client &&
      lastFilter.project &&
      lastFilter.coordinator &&
      lastFilter.periodYear &&
      lastFilter.periodMonth &&
      lastFilter.startPeriod &&
      lastFilter.endPeriod &&
      lastFilter.initialCycleDay &&
      flexiClosureDates.length > 0
    ) {
      console.log("Chamando onSubmit com os dados:", lastFilter);
      onSubmit(lastFilter);
    }
  }, [lastFilter, flexiClosureDates]); // Apenas chama onSubmit se esses valores mudarem



  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle icon={EndOfPeriodIcon} title="Fechamento Flexível" />
        <br></br>
        <Filters
          reload={reload}
          onSubmit={onSubmit}
          setReload={setReload}
          filterOptions={filterOptions}
          loading={loading}
          setLoading={setLoading}
          client={clientPreSelected}
          project={projectPreSelected}
          flexiClosureDates={flexiClosureDates}
          setFlexiClosureDates={setFlexiClosureDates}
        />
        <br />
        {
          flexiClosureDates?.map((fcd) => {
            return (
              <Fragment key={"FlexiClosureDate_" + fcd.idFlexiClosureDate}>
                <h5>
                  Lançamento: {moment(fcd.startDate).format("DD/MM/yyyy")} à{" "}
                  {moment(fcd.endDate).format("DD/MM/yyyy")}
                </h5>
                <Card>
                  <Card.Body>
                    <div className="user-list">
                      {(() => {
                        // Continue com a lógica de renderização
                        if (fcd.projectCurrentCycles?.length > 0) {
                          return fcd.projectCurrentCycles.map((item) => {
                            let totalLoadedMinutes = 0;
                            let totalAllocatedMinutes = 0;
                            item.loadMinutesProjectList.forEach((project) => {
                              totalAllocatedMinutes += project.totalAllocatedMinutes;
                              totalLoadedMinutes += project.totalLoadedMinutes;
                            });

                            return (
                              <Fragment
                                key={
                                  "fcd_" +
                                  fcd.idFlexiClosureDate +
                                  "_" +
                                  item.professional.idUser +
                                  "_first"
                                }
                              >
                                <ApprovalCard
                                  user={item.professional.idUser}
                                  title={item.professional.name}
                                  noteForBilling={item.professional.noteForBilling}
                                  totalAllocatedMinutes={totalAllocatedMinutes}
                                  totalLoadedMinutes={totalLoadedMinutes}
                                  totalTime={item.totalMinutes}
                                  projectList={item.loadMinutesProjectList}
                                  setLoading={setLoading}
                                  setReload={setReload}
                                  period={period}
                                  startPeriod={fcd.startDate}
                                  endPeriod={fcd.endDate}
                                />
                              </Fragment>
                            );
                          });
                        }
                        return null;
                      })()}
                    </div>
                  </Card.Body>

                </Card>
              </Fragment>
            );
          })
        }
      </div>
    </>
  );
};

export default EndOfPeriodFlex;
