/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles/";
import Swal from "sweetalert2";
import { RiSearchLine } from "react-icons/ri";
import { debounce } from "lodash";
import { InexApiService } from "../../services/InexApiService";
import HeaderButton from "../../pages/admin/components/headerButton";
import { InputField } from "../../pages/admin/components/InputField";
// import RefreshButton from "../../pages/admin/components/RefreshButton";
import Loading from "../../pages/home/components/Loading";
import EditorConvertToHTML from "../../pages/admin/components/EditorConvertToHTML";
import { StyledTableCell, StyledTableRow } from "./styles";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import {
  Table,
  TableBody,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableFooter,
  Paper,
  TableCell
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { AdminTitle } from "../../pages/admin/components/AdminTitle";
import { HeaderSelect } from "../../pages/admin/components/HeaderSelect";

import toggleOnIcon from "../../assets/icons/icon-toggle-on.svg";
import toggleOffIcon from "../../assets/icons/icon-toggle-off.svg";
import IconButton from "@material-ui/core/IconButton/";
import store from "../../store/store";
import * as authDuck from "../../store/ducks/auth.duck";
import moment from "moment";

const EnhancedTableHead = props => {
  const { order, orderBy, onRequestSort, headRows } = props;

  const createSortHandler = property => event => {
    if (!property) {
      return;
    }

    onRequestSort(event, property);
  };

  const generateRow = headRows => (
    <TableRow>
      {(headRows || []).map((row, i) => (
        <TableCell
          key={row.column || i}
          align={row.align ? row.align : "left"}
          padding={row.disablePadding ? "none" : "default"}
          sortDirection={orderBy === row.column ? order : false}
          size={row.size || null}
          width={row.width || undefined}
          variant="head"
          component={
            props.styledTableCell !== undefined
              ? props.styledTableCell
              : StyledTableCell
          }
        >
          {row.column ? (
            <TableSortLabel
              active={orderBy === row.column}
              direction={order}
              onClick={createSortHandler(row.column)}
            >
              {row.label}
            </TableSortLabel>
          ) : (
            row.label
          )}
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <TableHead>
      {Array.isArray(headRows[0]) &&
        headRows.map(row => {
          return generateRow(row);
        })}
      {!Array.isArray(headRows[0]) && generateRow(headRows)}
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.array.isRequired
};

const api = new InexApiService();

function Datatable(props) {
  const { widthTable } = props;
  const useStyles = makeStyles(() => {
    return {
      root: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%"
      },
      paper: {
        width: "100%",
        flex: "1"
      },
      table: {
        minWidth: widthTable ? widthTable : 400,
        overflowX: "scroll"
      },
      tableWrapper: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        overflowX: props.overflowX ?? "auto",
        boxShadow: props.removeShadow
          ? "none"
          : "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      },
      search: {
        backgroundColor: "#ffffff",
        display: "flex",
        flexWrap: "wrap"
      },
      addBtn: {
        padding: "24px 0",
        marginLeft: "16px"
      },
      selectFilters: {
        display: "flex",
        maxWidth: "300px",
        width: "16%",
        padding: "24px 0",
        marginLeft: "16px"
      },
      inputFieldProfessional: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: "16px",
        width: "20%",
        marginRight: "17px"
      }
    };
  });
  const classes = useStyles();
  const currentState = loadPaginationState();
  const { auth } = store.getState();
  const rows = props.rows;
  const [order, setOrder] = React.useState(
    currentState.orderByDirection || "desc"
  );
  const [orderBy, setOrderBy] = React.useState(
    currentState.orderBy || props.orderBy || props.headRows[0].column || "Id"
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    auth.user.rowsPerPage || props.rowsPerPage || currentState.perPage || 50
  );
  const [count, setCount] = React.useState(rows.length);
  const [inputSearch, setInputSearch] = React.useState(props.search || "");
  const [isLoading, setIsLoading] = React.useState("");
  const [isLoadingExport, setIsLoadingExport] = React.useState("");
  const [reload, setReload] = React.useState(props.reload || false);
  const pageCurrentState = currentState.page;
  const [professional, setProfessional] = React.useState({
    value: "",
    label: ""
  });
  const [trainingGroup, setTrainingGroup] = React.useState({
    value: "",
    label: "TODOS"
  });
  const [trainingSubgroup, setTrainingSubgroup] = React.useState({
    value: "",
    label: "TODOS"
  });

  const [showOnlyActiveProjectOpt, setShowOnlyActiveProjectOpt] = useState(
    true
  );
  const [idCliente, setIdCliente] = React.useState(
    props.dateRange?.idClient || null
  );
  const [generalDate, setGeneralDate] = React.useState(
    props.dateRange?.generalDate || null
  );
  const [loadTimer, setLoadTimer] = React.useState(false);
  const [projectFilter, setProjectFilter] = React.useState(
    props.projectTimerFilter || null
  );

  const onSearch = useRef(
    debounce((e, searchLoad) => {
      if (e.length >= 3 || e.length === 0) {
        searchLoad(e);
      }
    }, 500)
  ).current;

  useEffect(() => {
    if (props.dateRange?.idClient !== undefined) {
      setIdCliente(props.dateRange.idClient);
    }
  }, [props.dateRange]);

  function search(e, select) {
    const value = e.target.value || ""; // define valor como string vazia caso seja undefined
    setInputSearch(value);
    props.localSearch || select
      ? onSearch(value, e => props.localFilter(select, e, setCount))
      : onSearch(value, e => searchLoad(e));
  }

  function searchLoad(e) {
    loadRecords({
      page: 0,
      search: e,
      filterType: props.filterType
    });
  }

  useEffect(() => {
    if (props.search) {
      search({ target: { value: props.search } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.search]);

  useEffect(() => {
    if (props.operationUser) {
      loadRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.operationUser]);

  useEffect(() => {
    if (props.filterStatusWhatsappMessage) {
      loadRecords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filterStatusWhatsappMessage]);

  async function exportRecords(config = {}) {
    setIsLoadingExport(true);

    let params = {
      page: (config.page !== undefined ? config.page : page) + 1,
      perPage: config.perPage || rowsPerPage,
      orderBy: config.orderBy || orderBy,
      orderByDirection: config.orderByDirection || order,
      FilterString: config.search,
      filterType: props.filterType,
      status: config.status ? config.status.join(", ") : ""
    };

    await api.download(
      {
        url: config.isEndpointFull
          ? config.endpoint || ""
          : props.endpoint + (config.endpoint || ""),
        params
      },
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );

    setIsLoadingExport(false);
  }

  async function loadRecords(config = {}) {
    if (props.endpoint) {
      setIsLoading(true);
      let params = {
        page: (config.page !== undefined ? config.page : page) + 1,
        perPage: config.perPage || rowsPerPage,
        orderBy: config.orderBy || orderBy,
        orderByDirection: config.orderByDirection || order,
        filterString: config.search,
        filterType: config.filterType,
        ritualType: props.ritualType ?? null,
        startPeriod: props.dateRange
          ? moment(props.dateRange.startPeriod).format("YYYY-MM-DD")
          : null,
        endPeriod: props.dateRange
          ? moment(props.dateRange.endPeriod).format("YYYY-MM-DD")
          : null,
        periodSeted: props.dateRange ? props.dateRange.periodSeted : false,
        initialCycleDay: props.dateRange ? props.dateRange.initialCycleDay : 1,
        idCliente: props.dateRange ? props.dateRange.idClient : null,
        hasPeriod: Boolean(props.dateRange),
        IdOperation: props.operationUser ? props.operationUser : null,
        filterStatusWhatsappMessage: props.filterStatusWhatsappMessage
          ? props.filterStatusWhatsappMessage
          : null,
        generalDate: props.dateRange
          ? props.dateRange.generalDate ?? null
          : null,
        projectTypeFilter: props.projectTimerFilter
          ? props.projectTimerFilter.type ?? null
          : null,
        projectIdFilter: props.projectTimerFilter
          ? props.projectTimerFilter.value ?? null
          : null,
        ...(props.professionalList && props.professionalList.length
          ? { idProfessional: professional.value }
          : {}), //Adiciona o parametro idProfessional apenas se a propriedade props.professionalList estiver passada e preenchida.
        ...(props.trainingGroupList && props.trainingGroupList.length
          ? { idTrainingGroup: trainingGroup.value }
          : {}),
        ...(props.trainingSubgroupList && props.trainingSubgroupList.length
          ? { idTrainingSubgroup: trainingSubgroup.value }
          : {}),
        ...(props.title === "Projetos / Squads"
          ? { showOnlyActive: showOnlyActiveProjectOpt }
          : {}), //Adiciona o parametro showOnlyActive apenas se a propriedade props.professionalList estiver passada e preenchida, pois ela indica que a pagina que estmos usando é a de projetos.
        ...config.filters
      };

      try {
        if (!props?.isTimerDatatable) {
          const response = await api.makeHttpRequest({
            url: props.endpoint,
            params
          });

          if (props.setCountAndFilter) {
            props.setCountAndFilter(response.total, {
              ...params,
              page: 1,
              perPage: response.total,
              status: config.status ? config.status : []
            });
          }

          if (props.notPaginated) {
            setCount(response?.length ?? 0);
            props.setRows(response ?? []);
          } else {
            setCount(response.total);
            props.setRows(response.data);
            setPage(
              params.page < response.totalPages
                ? params.page - 1 > 0
                  ? params.page - 1
                  : 0
                : response.totalPages - 1 > 0
                ? response.totalPages - 1
                : 0
            );
          }

          savePaginationState(params);

          if (inputSearch !== config.search) {
            setInputSearch(config.search);
          }
        } else {
          if (params.filterString && params.idCliente && params.periodSeted) {
            const response = await api.makeHttpRequest({
              url: props.endpoint,
              params
            });

            if (props.setCountAndFilter) {
              props.setCountAndFilter(response.total, {
                ...params,
                page: 1,
                perPage: response.total,
                status: config.status ? config.status : []
              });
            }

            if (props.notPaginated) {
              setCount(response?.length ?? 0);
              props.setRows(response ?? []);
            } else {
              setCount(response.total);
              props.setRows(response.data);
              setPage(
                params.page < response.totalPages
                  ? params.page - 1 > 0
                    ? params.page - 1
                    : 0
                  : response.totalPages - 1 > 0
                  ? response.totalPages - 1
                  : 0
              );
            }

            savePaginationState(params);

            if (inputSearch !== config.search) {
              setInputSearch(config.search);
            }
          }
        }
      } catch (e) {
        Swal.fire(
          "Erro!",
          e?.response?.data
            ? e.response.data
            : "Erro ao carregar os dados, tente novamente mais tarde.",
          "error"
        );
      } finally {
        setIsLoading(false);
        setReload(false);
      }
    }
  }

  useEffect(() => {
    if (!props.cancelReload) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page,
        search: props.search || inputSearch,
        filterType: props.filterType
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reload]);

  useEffect(() => {
    if (
      Boolean(props.dateRange) ||
      Boolean(props.filters) ||
      props.filterType !== undefined
    ) {
      loadRecords({
        page:
          props.skipPaginationState === false ? pageCurrentState || page : page,
        search: props.search || inputSearch,
        filterType: props.filterType,
        filters: props.filters || {}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dateRange, props.filterType, props.filters]);

  function loadPaginationState() {
    const paginationState = JSON.parse(
      localStorage.getItem("pagination") || "{}"
    );
    if (paginationState[props.endpoint]) {
      return paginationState[props.endpoint];
    }

    return {};
  }

  function savePaginationState(state) {
    const paginationState = JSON.parse(
      localStorage.getItem("pagination") || "{}"
    );
    state.page--;
    paginationState[props.endpoint] = state;

    localStorage.setItem("pagination", JSON.stringify(paginationState));
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    const direction = isDesc ? "asc" : "desc";
    setOrder(direction);
    setOrderBy(property);
    loadRecords({
      orderByDirection: direction,
      orderBy: property === "Perfil" ? "Perfil.Nome" : property,
      search: inputSearch,
      filterType: props.filterType,
      filters: props.filters || {}
    });
  }

  function handleChangePage(_, newPage) {
    setPage(newPage);
    loadRecords({
      page: newPage,
      search: inputSearch,
      filterType: props.filterType,
      filters: props.filters || {}
    });
  }

  function handleChangeProfessional(e, select2Event) {
    if (select2Event?.name === "professional") {
      setProfessional(e);
    }
  }

  function handleChangeTrainingGroup(e, select2Event) {
    if (select2Event?.name === "trainingGroup") {
      setTrainingGroup(e);
    }
  }

  function handleChangeTrainingSubgroup(e, select2Event) {
    if (select2Event?.name === "trainingSubgroup") {
      setTrainingSubgroup(e);
    }
  }

  //se for alterado a variavel professional, forçamos o load dos registros considerando a alteração
  useEffect(() => {
    if (
      (props.professionalList && props.professionalList.length > 0) ||
      (props.trainingGroupList && props.trainingGroupList.length > 0) ||
      (props.trainingSubgroupList && props.trainingSubgroupList.length > 0)
    ) {
      loadRecords();
    }
  }, [professional]);

  const toggleChangeShowAllProject = value => {
    setShowOnlyActiveProjectOpt(value);
  };

  //se for alterado a variavel showOnlyActiveProjectOpt, forçamos o load dos registros considerando a alteração
  useEffect(() => {
    //Esse if Existe para garantir que só vai executar esse novo carregamento se:
    //1º estamos na tela de Projetos (onde temos a propriedade props.professionalList) essa propriedade garante que estamos na tela de projetos (da para melhorar isso um dia)
    //2º que só execute quando a variavel showOnlyActiveProjectOpt for alterada.
    if (props.professionalList && props.professionalList.length) {
      loadRecords();
    }
  }, [showOnlyActiveProjectOpt]);

  useEffect(() => {
    if (props.trainingGroupList && props.trainingGroupList.length) {
      loadRecords();
    }
  }, [trainingGroup]);

  useEffect(() => {
    if (props.trainingSubgroupList && props.trainingSubgroupList.length) {
      loadRecords();
    }
  }, [trainingSubgroup]);

  function handleChangeRowsPerPage(event) {
    setPage(0);
    setRowsPerPage(+event.target.value);
    loadRecords({
      perPage: event.target.value,
      page: 0,
      search: inputSearch,
      filterType: props.filterType,
      filters: props.filters || {}
    });
    if (auth.user.rowsPerPage !== event.target.value) {
      store.dispatch(authDuck.actions.changeRowsPerPage(event.target.value));
      api.makeHttpRequest({
        method: "PUT",
        url: "/user/update/rows-per-page/" + event.target.value
      });
    }
  }

  function refreshAction() {
    loadRecords({
      page: pageCurrentState || page,
      search: inputSearch,
      filterType: props.filterType
    });
  }

  if (reload) {
    refreshAction();
  }

  const generateMobileButtons = () => {
    return (
      <>
        {!props.hiddenReload && (
          <li className="kt-nav__item">
            <a href={void 0} className="kt-nav__link" onClick={refreshAction}>
              <i className="kt-nav__link-icon flaticon2-reload"></i>
              <span className="kt-nav__link-text">Atualizar</span>
            </a>
          </li>
        )}

        {props.buttons.length > 0
          ? props.buttons.map((btn, i) => {
              const btnOnClick = btn.endpoint
                ? () =>
                    exportRecords({
                      orderByDirection: order,
                      orderBy: orderBy,
                      search: inputSearch,
                      endpoint: btn.endpoint,
                      isEndpointFull: btn.isEndpointFull
                    })
                : btn.onClick;

              return btn.path ? (
                <Link to={btn.path} className="kt-nav__item" key={i}>
                  <span className="kt-nav__link" onClick={btnOnClick}>
                    <i
                      className={`kt-nav__link-icon flaticon-${btn.icone}`}
                    ></i>
                    <span className="kt-nav__link-text">{btn.label}</span>
                  </span>
                </Link>
              ) : (
                <li className="kt-nav__item" key={i}>
                  <a
                    href={void 0}
                    className="kt-nav__link"
                    onClick={btnOnClick}
                  >
                    <i
                      className={`kt-nav__link-icon flaticon-${btn.icone}`}
                    ></i>
                    <span className="kt-nav__link-text">{btn.label}</span>
                  </a>
                </li>
              );
            })
          : null}
      </>
    );
  };

  return (
    <div
      className={`col-xl-12 ${props.className}`}
      style={{ marginTop: "10px", minHeight: "max-content" }}
    >
      <Loading isLoading={isLoading || isLoadingExport} />
      <Portlet fluidHeight={true} style={{ backgroundColor: "transparent" }}>
        {props.title && props.icon ? (
          <AdminTitle
            icon={props.icon}
            title={props.title}
            disableHeader={props.disableHeader}
            width={props.width}
            mobile={props.mobile}
            actions={props.actions}
            refreshAction={props.refreshAction}
            generateMobileButtons={generateMobileButtons}
          />
        ) : null}

        <PortletBody fit={true} fluid={true}>
          <div className={classes.root}>
            {props.formView ? props.formView : null}

            {props.placeholderSearch ? (
              <div className={classes.search}>
                <div
                  className="kt-input-icon kt-input-icon--left"
                  style={{ flex: 1, minWidth: "235px", padding: "24px 0" }}
                >
                  <input
                    style={{
                      height: "48px",
                      borderColor: "#4D4C4D",
                      fontFamily: "'Cera Pro Light', sans-serif",
                      paddingLeft: "56px"
                    }}
                    type="text"
                    className="form-control"
                    placeholder={props.placeholderSearch}
                    onChange={search}
                    value={inputSearch}
                    maxLength={props?.maxLength || undefined}
                  />
                  <span
                    className="kt-input-icon__icon kt-input-icon__icon--left"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "0 8px"
                    }}
                  >
                    <RiSearchLine size="24px" color="#4D4C4D" />
                  </span>
                </div>
                {/* Aqui o campo profissional deve aperecer apenas na pagina de gestão de Projetos e não nas demais  */}
                {props.professionalList ? (
                  <div className={classes.inputFieldProfessional}>
                    <InputField
                      as="select2"
                      name="professional"
                      label="professional"
                      value={professional || ""}
                      onChange={handleChangeProfessional}
                      placeholder="Selecione o Profissional"
                      options={props.professionalList || ""}
                      closeMenuOnSelect={true}
                    />
                  </div>
                ) : null}

                {/* Aqui o campo grupo deve aperecer apenas na pagina de gestão de Subgrupos e Treinamentos e não nas demais  */}
                {props.trainingGroupList ? (
                  <div className={classes.inputFieldProfessional}>
                    <InputField
                      as="select2"
                      name="trainingGroup"
                      label="Grupos"
                      value={trainingGroup || ""}
                      onChange={handleChangeTrainingGroup}
                      placeholder="Selecione o Grupo"
                      options={props.trainingGroupList || ""}
                      closeMenuOnSelect={true}
                    />
                  </div>
                ) : null}

                {/* Aqui o campo grupo deve aperecer apenas na pagina de gestão de Treinamentos e não nas demais  */}
                {props.trainingSubgroupList ? (
                  <div className={classes.inputFieldProfessional}>
                    <InputField
                      as="select2"
                      name="trainingSubgroup"
                      label="Subgrupos"
                      value={trainingSubgroup || ""}
                      onChange={handleChangeTrainingSubgroup}
                      placeholder="Selecione o Subgrupo"
                      options={props.trainingSubgroupList || ""}
                      closeMenuOnSelect={true}
                    />
                  </div>
                ) : null}

                {/* Aqui o toggle para trazer apenas registros ativo deve estar visivel apenas na pagina de gestão de Projetos e não nas demais por hora  */}
                {props.title === "Projetos / Squads" ? (
                  <>
                    {showOnlyActiveProjectOpt ? (
                      <IconButton
                        size="small"
                        color="primary"
                        className={classes.button}
                        title="Inativar"
                        onClick={() => toggleChangeShowAllProject(false)}
                      >
                        <img src={toggleOnIcon} alt="Inativar" />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="small"
                        className={classes.button}
                        title="Ativar"
                        onClick={() => toggleChangeShowAllProject(true)}
                      >
                        <img src={toggleOffIcon} alt="Ativar" />
                      </IconButton>
                    )}
                  </>
                ) : null}

                {props.width >= 768 || !props.mobile ? (
                  <>
                    {props.selects && props.selects.length > 0
                      ? props.selects.map((item, index) => (
                          <div className={classes.selectFilters} key={index}>
                            <HeaderSelect
                              placeholder={item.placeholder}
                              value={item.value}
                              onChange={item.onChange}
                              options={item.options}
                            />
                          </div>
                        ))
                      : null}

                    {props.buttons && props.buttons.length > 0 ? (
                      <>
                        {props.buttons.map((item, index) => (
                          <div className={classes.addBtn} key={index}>
                            <HeaderButton
                              path={item.path}
                              label={item.label}
                              onClick={
                                item.endpoint
                                  ? () =>
                                      exportRecords({
                                        orderByDirection: order,
                                        orderBy: orderBy,
                                        search: inputSearch,
                                        endpoint: item.endpoint,
                                        isEndpointFull: item.isEndpointFull
                                      })
                                  : item.onClick
                              }
                              icone={item.icone}
                              RIcone={item.RIcone}
                              disabled={item.disabled}
                            />
                          </div>
                        ))}

                        {/* {!props.hiddenReload && (
                          <RefreshButton refreshAction={refreshAction} />
                        )} */}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            ) : null}

            {props.editorEmail ? (
              <EditorConvertToHTML
                resetEditor={props.resetEditor}
                getEmail={(html, assunto) => props.setEmail(html, assunto)}
              />
            ) : null}

            <Paper className={classes.paper}>
              <div className={classes.tableWrapper}>
                <Table id="datatable-table" className={classes.table}>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    headRows={props.headRows}
                    styledTableCell={props.styledTableCell}
                  />
                  <TableBody style={{ height: "100%" }}>
                    {isLoading ? (
                      <TableRow
                        component={
                          props.styledTableRow !== undefined
                            ? props.styledTableRow
                            : StyledTableRow
                        }
                      >
                        <TableCell
                          colSpan={props.headRows.length}
                          className="text-center"
                          component={
                            props.styledTableCell !== undefined
                              ? props.styledTableCell
                              : StyledTableCell
                          }
                        >
                          Aguarde...
                        </TableCell>
                      </TableRow>
                    ) : count > 0 ? (
                      rows.map((row, i) => props.formatRow(row, i))
                    ) : (
                      <TableRow
                        component={
                          props.styledTableRow !== undefined
                            ? props.styledTableRow
                            : StyledTableRow
                        }
                      >
                        <TableCell
                          colSpan={props.headRows.length}
                          className="text-center"
                          component={
                            props.styledTableCell !== undefined
                              ? props.styledTableCell
                              : StyledTableCell
                          }
                        >
                          Nenhum registro encontrado.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!props.hiddenFooterPagination ? (
                  <Table>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[50, 200, 1000]}
                          colSpan={10}
                          count={count || 0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          labelRowsPerPage="Registros por página"
                          backIconButtonProps={{
                            "aria-label": "Anterior"
                          }}
                          nextIconButtonProps={{
                            "aria-label": "Próxima"
                          }}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                ) : null}
              </div>
            </Paper>
          </div>
        </PortletBody>
      </Portlet>
    </div>
  );
}

export default Datatable;
