import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../../../services/InexApiService";
import { InputField } from "../../../../components/InputField";
import { ButtonContainer, CloseField, StyledModal } from "./styles";
import _, { filter } from "lodash";
import { HiX } from "react-icons/hi";
import { useSelector } from "react-redux";

const tableHeads = [
  { label: "Status", value: "Status" },
  { label: "Nome", value: "Nome" },
  { label: "Perfil", value: "Perfil" },
  { label: "Matrícula INEX", value: "Matrícula INEX" },
  { label: "Matrícula Cliente", value: "Matrícula Cliente" },
  { label: "Coordenador INEX", value: "Coordenador INEX" },
  { label: "Cliente", value: "Cliente" },
  { label: "Projeto (Coordenação)", value: "Projeto (Coordenação)" },
  { label: "Projeto (Área/Squad)", value: "Projeto (Área/Squad)" },
  { label: "Gerente Comercial", value: "Gerente Comercial" },
  { label: "Regime de Contratação", value: "Regime de Contratação" },
  { label: "E-mail INEX", value: "E-mail INEX" },
  { label: "Celular", value: "Celular" },
  { label: "Data Nascimento", value: "Data Nascimento" },
  { label: "Data Início", value: "Data Início" },
  { label: "Histórico", value: "Histórico" },
  { label: "Proposta Técnica", value: "Proposta Técnica" },
  { label: "Proposta Comercial (RFQ)", value: "Proposta Comercial (RFQ)" },
  { label: "Data de Modificação", value: "Data de Modificação" }
];

export const UserModal = ({ open, setOpen }) => {
  const api = new InexApiService();
  const user = useSelector(state => state.auth.user);

  const [disableButton, setDisableButton] = React.useState(false);

  const [filters, setFilters] = React.useState({
    idProjectArea: "",
    client: user.clientDefault.idClient
      ? user.clientDefault.idClient.toString()
      : "",
    idClient: "",
    clientName: "",
    project: "",
    inexCoordinator: "",
    role: ""
  });

  const [fields, setFields] = React.useState(tableHeads);

  const [clientOptions, setClientOptions] = React.useState([]);
  const [projectOptions, setProjectOptions] = React.useState([]);
  const [projectAreaOptions, setProjectAreaOptions] = React.useState([]);
  const [coordinatorOptions, setCoordinatorOptions] = React.useState([]);
  const [roleOptions, setRoleOptions] = React.useState([]);

  const reportOptions = [
    {
      key: 0,
      value: "Relatório Geral"
    },
    {
      key: 1,
      value: "Profissionais sem lançamentos de horas"
    },
    {
      key: 2,
      value: "Profissionais alocados em Projetos"
    }
  ];
  const [reportSelected, setReportSelected] = React.useState(0);

  const reportWithoutLaunchedOptions = [
    {
      key: 0,
      value: "Últimos 3 meses"
    },
    {
      key: 1,
      value: "Últimos 6 meses"
    },
    {
      key: 2,
      value: "Últimos 12 meses"
    },
    {
      key: 3,
      value: "Último 18 meses"
    },
    {
      key: 4,
      value: "Sem lançamentos"
    }
  ];

  const [
    reportWithoutLaunchedSelected,
    setReportWithoutLaunchedSelected
  ] = React.useState(0);

  React.useEffect(() => {
    loadClientes();
    loadRoles();
  }, []);

  React.useEffect(() => {
    loadProject();
    loadCoordinator();
    loadProjectArea();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  React.useEffect(() => {
    if (open) {
      setFields(tableHeads);
    }
  }, [open]);

  React.useEffect(() => {
    if (fields?.length <= 0) {
      Swal.fire(
        "Nenhum campo selecionado",
        "Selecione os campos que deseja visualizar no relatório.",
        "warning"
      );
    }
  }, [fields]);

  const loadClientes = React.useCallback(async () => {
    const clientes = await api.makeHttpRequest({
      url: "/professional/clients"
    });

    setClientOptions(clientes ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadProject = React.useCallback(async () => {
    if (!!filters.client) {
      const employees = await api.makeHttpRequest({
        url: `/professional/client-professionals/${filters.client}`
      });

      if (employees && employees?.length <= 0) {
        Swal.fire(
          "Aviso!",
          "Não existem profissionais alocados a este cliente.",
          "warning"
        );

        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }

    const endpoint = filters.client
      ? `/professional/project/client/${filters.client}`
      : "/professional/projects";

    const project = await api.makeHttpRequest({
      url: endpoint,
      params: {
        isReport: true
      }
    });

    setProjectOptions(project ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  const loadProjectAllocated = React.useCallback(async () => {
    if (filters.client) {
      const employees = await api.makeHttpRequest({
        url: `/professional/client-professionals/${filters.client}`
      });

      if (employees && employees?.length <= 0) {
        Swal.fire(
          "Aviso!",
          "Não existem profissionais alocados a este cliente.",
          "warning"
        );

        setDisableButton(true);
      } else {
        setDisableButton(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  const loadCoordinator = React.useCallback(async () => {
    const endpoint = filters.client
      ? `/professional/coordinators/client/${filters.client}`
      : "/professional/coordinators";

    const coordinators = await api.makeHttpRequest({
      url: endpoint
    });

    setCoordinatorOptions(coordinators ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  const loadProjectArea = React.useCallback(async () => {
    const endpoint = filters.client
      ? `/professional/project/areas/client/${filters.client}`
      : "/professional/project/areas";

    const projectAreas = await api.makeHttpRequest({
      url: endpoint
    });

    setProjectAreaOptions(projectAreas ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.client]);

  const loadRoles = React.useCallback(async () => {
    const roles = await api.makeHttpRequest({
      url: `/role/all`
    });

    setRoleOptions(roles ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = e => {
    if (e.target.name === "clientAllocated") {
      const client = clientOptions.filter(
        c => c.idClient === parseInt(e.target.value.client)
      )[0];
      loadProjectAllocated(e.target.value.client);
      setFilters({
        ...filters,
        clientName: client.name,
        client: e.target.value.client,
        idClient: e.target.value.client
      });
    } else {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    }
  };

  const handleDeleteFields = data => {
    const newFields = _.filter(fields, field => {
      return !_.isEqual(field, data);
    });

    setFields(newFields);
  };

  return (
    <>
      <StyledModal show={open} onHide={() => setOpen(false)} size="lg" centered>
        <Modal.Body>
          <CloseField>
            <HiX onClick={() => setOpen(false)} />
          </CloseField>
          <Form>
            {/* Seleção de Relatório */}
            <Form.Row>
              <Form.Group as={Col} lg="12" xs="12">
                <InputField
                  as="select"
                  name="reportSelected"
                  label="Tipos de Relatórios"
                  value={reportSelected}
                  onChange={e => {
                    if (parseInt(e.target.value) === 2) {
                      setDisableButton(true);
                    } else {
                      setDisableButton(false);
                    }
                    setFilters({
                      idProjectArea: "",
                      client: "",
                      idClient: "",
                      clientName: "",
                      project: "",
                      inexCoordinator: ""
                    });
                    setReportSelected(parseInt(e.target.value));
                  }}
                >
                  {reportOptions?.length > 0 &&
                    reportOptions.map(r => (
                      <option key={r.key} value={r.key}>
                        {r.value}
                      </option>
                    ))}
                </InputField>
              </Form.Group>
            </Form.Row>
            {reportSelected === 0 && (
              <>
                {/* row 1 */}
                <Form.Row>
                  {/* Cliente */}
                  <Form.Group as={Col} lg="6" xs="12">
                    <InputField
                      as="select"
                      name="client"
                      label="Cliente"
                      value={filters.client}
                      onChange={handleChange}
                    >
                      {clientOptions?.length > 0 &&
                        clientOptions.map(c => (
                          <option key={c.idClient} value={c.idClient}>
                            {c.name}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                  {/* Coordenação */}
                  <Form.Group as={Col} lg="6" xs="12">
                    <InputField
                      as="select"
                      name="idProjectArea"
                      label="Coordenação"
                      value={filters.idProjectArea}
                      onChange={handleChange}
                      placeholder="Selecione"
                    >
                      <option value="">Selecione</option>
                      {projectAreaOptions?.length > 0 &&
                        projectAreaOptions.map(p => (
                          <option key={p.idProjectArea} value={p.idProjectArea}>
                            {p.name}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                </Form.Row>

                {/* row 2 */}
                <Form.Row>
                  {/* Projeto */}
                  <Form.Group as={Col} lg="6" xs="12">
                    <InputField
                      as="select"
                      name="project"
                      label="Projeto"
                      placeholder="Selecione"
                      value={filters.project}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      {projectOptions?.length > 0 &&
                        projectOptions.map(p => (
                          <option key={p.idProject} value={p.idProject}>
                            {p.projectArea?.name + " - " + p.name}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                  {/* Coordinator de Operações) */}
                  <Form.Group as={Col} lg="6" xs="12">
                    <InputField
                      as="select"
                      name="inexCoordinator"
                      label="Coordenador de Operações"
                      value={filters.inexCoordinator}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      {coordinatorOptions?.length > 0 &&
                        coordinatorOptions.map(c => (
                          <option key={c.idUser} value={c.idUser}>
                            {c.name}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                </Form.Row>
                {/* row 3 */}
                <Form.Row>
                  {/* Perfis */}
                  <Form.Group as={Col} lg="18" xs="12">
                    <InputField
                      as="select"
                      name="role"
                      label="Perfil"
                      value={filters.role}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      {roleOptions?.length > 0 &&
                        roleOptions.map(c => (
                          <option key={c.idRole} value={c.idRole}>
                            {c.translatePtBr}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                </Form.Row>
                {/* row 4 */}
                <Form.Row>
                  {/* Campos Exibidos no Relatório */}
                  <Form.Group as={Col} lg="18" xs="12">
                    <InputField
                      as="select2"
                      isMulti
                      name="inexCoordinator"
                      label="Campos Exibidos no Relatório"
                      value={fields}
                      onChange={e => setFields(e)}
                      onDelete={e => handleDeleteFields(e)}
                      options={tableHeads}
                    />
                  </Form.Group>
                </Form.Row>
              </>
            )}
            {reportSelected === 1 && (
              <Form.Row>
                <Form.Group as={Col} lg="12" xs="12">
                  <InputField
                    as="select"
                    name="filter"
                    label="Filtro"
                    value={reportWithoutLaunchedSelected}
                    onChange={e =>
                      setReportWithoutLaunchedSelected(parseInt(e.target.value))
                    }
                  >
                    {reportWithoutLaunchedOptions?.length > 0 &&
                      reportWithoutLaunchedOptions.map(r => (
                        <option key={r.key} value={r.key}>
                          {r.value}
                        </option>
                      ))}
                  </InputField>
                </Form.Group>
              </Form.Row>
            )}
            {reportSelected === 2 && (
              <>
                {/* row 1 */}
                <Form.Row>
                  {/* Cliente */}
                  <Form.Group as={Col} lg="12" xs="12">
                    <InputField
                      as="select"
                      name="clientAllocated"
                      label="Cliente"
                      value={filters.idClient}
                      onChange={handleChange}
                    >
                      <option value="">Selecione</option>
                      {clientOptions?.length > 0 &&
                        clientOptions.map(c => (
                          <option key={c.idClient} value={c.idClient}>
                            {c.name}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                </Form.Row>
              </>
            )}
          </Form>
          <ButtonContainer>
            <Link
              to={location => {
                if (fields?.length <= 0) {
                  return;
                }

                if (disableButton) {
                  return;
                }

                const paransFields =
                  fields?.length > 0 ? fields.map(item => item.value) : [];

                const params =
                  reportSelected === 0
                    ? {
                        idClient: filters.client,
                        idProject: filters.project,
                        idCoordinator: filters.inexCoordinator,
                        idProjectArea: filters.idProjectArea,
                        idRole: filters.role,
                        columnNames: paransFields.toString()
                      }
                    : reportSelected === 1
                    ? {
                        reportWithoutLaunchedSelected: reportWithoutLaunchedSelected
                      }
                    : {
                        idClient: filters.idClient,
                        clientName: filters.clientName
                      };

                const qs = new URLSearchParams(params);

                return {
                  ...location,
                  pathname:
                    reportSelected === 0
                      ? "/admin/profissionais/relatorio"
                      : reportSelected === 1
                      ? "/admin/profissionais/relatorio-sem-lancamento"
                      : "/admin/profissionais/relatorio-alocados",
                  search: qs.toString()
                };
              }}
            >
              <button
                type="button"
                disabled={disableButton || fields?.length === 0}
              >
                ABRIR RELATÓRIO
              </button>
            </Link>
          </ButtonContainer>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
