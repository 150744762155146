import React from "react";
import { Col, Form, Modal } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import {
  StyledPlusIcon,
  StyledPasteIcon,
  StyledIconButton,
  StyledTrashIcon
} from "./styles";
import { InexApiService } from "../../../../../../services/InexApiService";
import InputMask from "react-input-mask";
import {
  Button,
  StyledModal,
  Fields,
  TitlePrj,
  Container,
  Period
} from "./styles";
import {
  transformMinutesToHours,
  transformHoursToMinutes
} from "../../../../../../utils/CalculateSumTime";
import { GetPeriodName } from "../../../../../../utils/GetYearsAndMonths";
import Swal from "sweetalert2";
import Loading from "../../../../../home/components/Loading";
import moment from "moment";
import _ from "lodash";

export const DemandModal = ({
  user,
  title,
  close,
  noteForBilling,
  projectList,
  setReload
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [noteForBillingLocal, setNoteForBillingLocal] = React.useState(
    noteForBilling || ""
  );
  const [loading, setLoading] = React.useState(false);

  const [projectListLocal, setProjectListLocal] = React.useState({});

  const api = new InexApiService();

  const loadLoadedMinutesByProject = async projectListTmp => {
    for (const projectItem of projectListTmp) {
      let idx = 0;

      for (const loadedMinutesItem of projectItem.loadedMinutesList) {
        loadedMinutesItem.idx = idx;
        loadedMinutesItem.allocatedMinutesHoursFormat = transformMinutesToHours(
          loadedMinutesItem.allocatedMinutes
        );
        loadedMinutesItem.loadedMinutesHoursFormat = transformMinutesToHours(
          loadedMinutesItem.loadedMinutes
        );
        idx++;
      }

      // Adicionar item extra se totalLoadedMinutes < totalMinutes e se não houver um item em loadedMinutesList com idClientLoadedMinutes igual a null
      if (
        (projectItem.totalLoadedMinutes < projectItem.totalMinutes &&
          !projectItem.loadedMinutesList.some(
            item => item.idClientLoadedMinutes === null
          )) ||
        projectItem.loadedMinutesList.length === 0
      ) {
        const idxLast = idx;
        projectItem.loadedMinutesList.push({
          demand: "",
          idClientLoadedMinutes: null,
          idx: idxLast,
          allocatedMinutes: 0,
          allocatedMinutesHoursFormat: "00:00:00",
          loadedMinutes: 0,
          loadedMinutesHoursFormat: "00:00:00",
          payingAreaName: projectItem.payingArea.name
        });
      }
    }
    setProjectListLocal(projectListTmp);
  };

  React.useEffect(() => {
    if (user > 0) {
      loadLoadedMinutesByProject(projectList);
    }
  }, [user]);

  const handleAddFieldsInProject = (event, idProject) => {
    if (event) {
      event.preventDefault();
    }

    const projectListTmp = _.cloneDeep(projectListLocal);

    const hasInvalidItem = _.some(projectListTmp[0].loadedMinutesList, item => {
      return (
        !item.demand ||
        !item.payingAreaName ||
        item.allocatedMinutesHoursFormat.length !== 8 ||
        item.loadedMinutesHoursFormat.length !== 8
      );
    });

    if (hasInvalidItem) {
      Swal.fire("Erro!", "Preencha todos os campos corretamente.");
      return;
    }

    for (const projectItem of projectListTmp) {
      // Adicionar item extra se totalLoadedMinutes < totalMinutes e se não houver um item em loadedMinutesList com idClientLoadedMinutes igual a null
      if (projectItem.idProject === idProject) {
        const maxIdx = projectListLocal
          .find(item => item.idProject === idProject)
          .loadedMinutesList.reduce(
            (acc, cur) => (cur.idx > acc ? cur.idx : acc),
            0
          );
        const newIdx = maxIdx + 1;
        projectItem.loadedMinutesList.push({
          demand: "",
          idClientLoadedMinutes: null,
          idx: newIdx,
          allocatedMinutes: 0,
          allocatedMinutesHoursFormat: "00:00:00",
          loadedMinutes: 0,
          loadedMinutesHoursFormat: "00:00:00",
          payingAreaName: projectItem.payingArea.name
        });
      }
    }

    setProjectListLocal(projectListTmp);
  };

  const handleDemandChange = e => {
    const projectListTmp = [...projectListLocal];
    const arrTargetName = e.target.name.split(";;");
    const targetPrjId = parseInt(arrTargetName[1]);
    const idx = parseInt(arrTargetName[2]);
    const period = parseInt(arrTargetName[3]);

    let inputValue = e.target.value;
    for (const projectItem of projectListTmp) {
      if (
        projectItem.idProject === targetPrjId &&
        projectItem.period === period
      ) {
        for (const loadedMinutesItem of projectItem.loadedMinutesList) {
          if (loadedMinutesItem.idx === idx) {
            loadedMinutesItem.demand = inputValue.trim();
          }
        }
      }
    }

    setProjectListLocal(projectListTmp);
  };

  const handleObservationChange = e => {
    if (e.target.value.length <= 100) {
      setNoteForBillingLocal(e.target.value);
    }
  };

  const handlePayingAreaChange = e => {
    const projectListTmp = [...projectListLocal];

    const arrTargetName = e.target.name.split(";;");
    const targetPrjId = parseInt(arrTargetName[1]);
    const idx = parseInt(arrTargetName[2]);
    const period = parseInt(arrTargetName[3]);

    let inputValue = e.target.value;
    for (const projectItem of projectListTmp) {
      if (
        projectItem.idProject === targetPrjId &&
        projectItem.period === period
      ) {
        for (const loadedMinutesItem of projectItem.loadedMinutesList) {
          if (loadedMinutesItem.idx === idx) {
            loadedMinutesItem.payingAreaName = inputValue.trim();
          }
        }
      }
    }

    setProjectListLocal(projectListTmp);
  };

  const handleAllocatedHoursChange = e => {
    console.log("action");
    const projectListTmp = [...projectListLocal];

    const arrTargetName = e.target.name.split(";;");
    const targetPrjId = parseInt(arrTargetName[1]);
    const idx = parseInt(arrTargetName[2]);
    const period = parseInt(arrTargetName[3]);

    let inputValue = e.target.value.replace(/[^\d:]/g, "").slice(0, 9);

    //Se o valor estiver vazio, atribuimos o horario zerado.
    if (inputValue === "") {
      inputValue = "00:00:00";
    }

    for (const projectItem of projectListTmp) {
      if (
        projectItem.idProject === targetPrjId &&
        projectItem.period === period
      ) {
        for (const loadedMinutesItem of projectItem.loadedMinutesList) {
          if (loadedMinutesItem.idx === idx) {
            loadedMinutesItem.allocatedMinutesHoursFormat = inputValue;
          }
        }
      }
    }

    setProjectListLocal(projectListTmp);
  };

  const handleAllocatedHoursBlur = e => {
    if (e.target.value.length < 8) {
      const valuesSplitted = e.target.value
        .replaceAll(":", "")
        .padEnd(6, "0")
        .match(/.{1,2}/g);
      e.target.value = valuesSplitted.join(":");
    }

    handleAllocatedHoursChange(e);
  };

  const handleLoadedHoursChange = e => {
    const projectListTmp = [...projectListLocal];

    const arrTargetName = e.target.name.split(";;");
    const targetPrjId = parseInt(arrTargetName[1]);
    const idx = parseInt(arrTargetName[2]);
    const period = parseInt(arrTargetName[3]);

    let inputValue = e.target.value.replace(/[^\d:]/g, "").slice(0, 9);

    //Se o valor estiver vazio, atribuimos o horario zerado.
    if (inputValue === "") {
      inputValue = "00:00:00";
    }

    for (const projectItem of projectListTmp) {
      if (
        projectItem.idProject === targetPrjId &&
        projectItem.period === period
      ) {
        for (const loadedMinutesItem of projectItem.loadedMinutesList) {
          if (loadedMinutesItem.idx === idx) {
            loadedMinutesItem.loadedMinutesHoursFormat = inputValue;
          }
        }
      }
    }

    setProjectListLocal(projectListTmp);
  };

  const handleLoadedHoursBlur = e => {
    if (e.target.value.length < 8) {
      const valuesSplitted = e.target.value
        .replaceAll(":", "")
        .padEnd(6, "0")
        .match(/.{1,2}/g);
      e.target.value = valuesSplitted.join(":");
    }

    handleLoadedHoursChange(e);
  };

  const handleModalConfirm = async e => {
    // AQUI FOI PRECISO CRIAR UMA COPIA PROFUNDA (DEEPCOPY)
    // POIS LA EM BAIXO, SE TIVERMOS RETIRAMOS UM ITEM DA LISTA,
    // E FAZENDO UMA COPIA SIMPLES, ELE APAGA TB DA LISTA ORIGINAL projectListLocal
    // E NÃO QUEREMOS QUE ISSO ACONTEÇA.
    // const projectListTmp = JSON.parse(JSON.stringify(projectListLocal));
    const projectListTmp = _.cloneDeep(projectListLocal);

    let allRight = true;
    //Pattern para aceitar apenas hh:mm:ss onde hh pode ser até 999, mm pode ser no maximo 59, e segundos devem ser sempre 00.
    let pattern = /^(?:[0-9]{1,4}\:)(?:[0-5][0-9]\:)(?:00)$/;

    outerloop: for (const projectItem of projectListTmp) {
      let totalAllocatedMinutes = 0;
      let totalLoadedMinutes = 0;
      const demandOccurrences = {};

      for (const loadedMinutesItem of projectItem.loadedMinutesList) {
        if (
          loadedMinutesItem.demand === "" &&
          (transformHoursToMinutes(loadedMinutesItem.loadedMinutesHoursFormat) >
            0 ||
            transformHoursToMinutes(
              loadedMinutesItem.allocatedMinutesHoursFormat
            ) > 0 ||
            loadedMinutesItem.idClientLoadedMinutes > 0)
        ) {
          Swal.fire(
            "Erro!",
            "É necessário preencher o código da Demanda do projeto: " +
              projectItem.name,
            "error"
          );
          allRight = false;
          break outerloop;
        }

        if (loadedMinutesItem.demand) {
          if (demandOccurrences[loadedMinutesItem.demand]) {
            Swal.fire(
              "Erro!",
              `A demanda ${loadedMinutesItem.demand} já foi adicionada no projeto ${projectItem.name}`,
              "error"
            );
            allRight = false;
            break outerloop;
          } else {
            demandOccurrences[loadedMinutesItem.demand] = true;
          }
        }

        if (
          transformHoursToMinutes(loadedMinutesItem.loadedMinutesHoursFormat) >
          transformHoursToMinutes(loadedMinutesItem.allocatedMinutesHoursFormat)
        ) {
          Swal.fire(
            "Erro!",
            `A quantidade de horas carregadas é maior do que as horas alocadas na demanda: 
            <br />
            <b>${loadedMinutesItem.demand} - ${projectItem.name}</b>
            `,
            "error"
          );
          allRight = false;
          break outerloop;
        }

        if (loadedMinutesItem.allocatedMinutesHoursFormat.length !== 8) {
          Swal.fire(
            "Erro!",
            "Preencha a hora alocada corretamente: " + projectItem.name,
            "error"
          );
          allRight = false;
          break outerloop;
        }

        if (loadedMinutesItem.loadedMinutesHoursFormat.length !== 8) {
          Swal.fire(
            "Erro!",
            "Preencha a hora carregadas corretamente: " + projectItem.name,
            "error"
          );
          allRight = false;
          break outerloop;
        }

        // Se nada deu erro, vamos recuperar o valor do campo digitavel e jogar para dentro das variaveis de banco loadedMinutes , allocatedMinutes e totalMinutes
        loadedMinutesItem.allocatedMinutes = transformHoursToMinutes(
          loadedMinutesItem.allocatedMinutesHoursFormat
        );
        loadedMinutesItem.loadedMinutes = transformHoursToMinutes(
          loadedMinutesItem.loadedMinutesHoursFormat
        );

        //Se o registro que estamos lendo ainda não tem idClientLoadedMinutes (isso significa que ele não foi criado no banco ainda)
        // E se elestiver com loadedMinutes ou allocatedMinutes zerado, então excluimos o registro para não ir para o backend.
        if (
          (loadedMinutesItem.idClientLoadedMinutes === null ||
            loadedMinutesItem.idClientLoadedMinutes < 1) &&
          loadedMinutesItem.loadedMinutes === 0 &&
          loadedMinutesItem.allocatedMinutes === 0
        ) {
          projectItem.loadedMinutesList = projectItem.loadedMinutesList.filter(
            item => item !== loadedMinutesItem
          );
        }

        totalAllocatedMinutes += transformHoursToMinutes(
          loadedMinutesItem.allocatedMinutesHoursFormat
        );
        totalLoadedMinutes += transformHoursToMinutes(
          loadedMinutesItem.loadedMinutesHoursFormat
        );
      }

      if (totalAllocatedMinutes > projectItem.totalMinutes) {
        Swal.fire(
          "Erro!",
          "A soma das horas alocadas no projeto " +
            projectItem.name +
            " excede o total de horas do projeto.",
          "error"
        );
        allRight = false;
        break;
      }

      if (totalLoadedMinutes > projectItem.totalMinutes) {
        Swal.fire(
          "Erro!",
          "A soma das horas carregadas no projeto " +
            projectItem.name +
            " excede o total de horas do projeto.",
          "error"
        );
        allRight = false;
        break;
      }
    }

    //Aqui verificamos se a anotação para faturamento foi alterada, se sim, efetuamos o ajuste no banco.
    if (noteForBillingLocal !== noteForBilling) {
      changeNoteForBilling(noteForBillingLocal);
    }

    //aqui tenho que percorrer novamente a lista, e validar se ela possui algum registro de loadedminute e só seguir se existir.
    let hasLoadedMinutesItem = false;
    for (const projectItem of projectListTmp) {
      for (const loadedMinutesItem of projectItem.loadedMinutesList) {
        if (
          (loadedMinutesItem.idClientLoadedMinutes === null ||
            loadedMinutesItem.idClientLoadedMinutes < 1) &&
          loadedMinutesItem.AllocatedMinutes === 0 &&
          loadedMinutesItem.loadedMinutes === 0
        ) {
          projectItem.loadedMinutesList = projectItem.loadedMinutesList.filter(
            item => item !== loadedMinutesItem
          );
        }
        hasLoadedMinutesItem = true;
        break; // aqui interrompe o loop
      }
    }

    if (!hasLoadedMinutesItem) {
      Swal.fire(
        "Aviso!",
        "Não há horas a serem Allocadas ou Carregadas.",
        "warning"
      );
      allRight = false;
    }

    if (allRight) {
      for (const projectItem of projectListTmp) {
        const totalLoadedMinutes = projectItem.loadedMinutesList.reduce(
          (total, item) => total + item.loadedMinutes,
          0
        );
        projectItem.totalLoadedMinutes = totalLoadedMinutes;
      }

      await loadMinutes(projectListTmp);
    }
  };

  const handleDeleteLoadedMinutesItem = async loadedItem => {
    if (loadedItem.idClientLoadedMinutes !== null) {
      await Swal.fire({
        title:
          "O item selecionado já possui registro, deseja deletá-lo mesmo assim?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar"
      }).then(async result => {
        if (result.value) {
          try {
            setLoading(true);
            const response = await api.makeHttpRequest({
              url: `/clientloadedminutes/delete-loaded-minutes/${loadedItem.idClientLoadedMinutes}`,
              method: "POST"
            });
            if (response) handleFilterLoadedMinutesList(loadedItem);
            setLoading(false);

            Swal.fire("Sucesso!", response, "success");
          } catch (e) {
            setLoading(false);
            Swal.fire(
              "Erro!",
              e?.response?.data ? e.response.data : "Erro.",
              "error"
            );
          }
        }
      });
    } else handleFilterLoadedMinutesList(loadedItem);
  };

  const handleFilterLoadedMinutesList = loadedItem => {
    const newLoadedMinutesList = _.cloneDeep(
      projectListLocal[0].loadedMinutesList
    );

    const filteredLoadedMinutesList = newLoadedMinutesList.filter(item => {
      return item.idx != loadedItem.idx;
    });

    setProjectListLocal(
      [...projectListLocal],
      (projectListLocal[0].loadedMinutesList = filteredLoadedMinutesList)
    );
  };

  const loadMinutes = async projectListTmp => {
    //Aqui Gravamos a lista dos carregamentos na tabela do Banco.
    try {
      await api.makeHttpRequest({
        url: "/clientloadedminutes/load-minutes",
        method: "POST",
        data: {
          Professional: { IdUser: user },
          LoadMinutesProjectList: projectListTmp
        }
      });

      Swal.fire(
        "Sucesso!",
        "Sucesso ao atribuir Horas Carregadas e Código de Demanda.",
        "success"
      );
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao atribuir Horas Carregadas e Código de Demanda.",
        "error"
      );
    }

    setProjectListLocal(projectListTmp);

    loadLoadedMinutesByProject(projectListTmp);

    setReload(true);
    close();
  };

  const changeNoteForBilling = async NewNoteForBilling => {
    try {
      await api.makeHttpRequest({
        url: `/professional/updateNoteForBilling/${user}`,
        method: "PUT",
        data: {
          NoteForBilling: NewNoteForBilling
        }
      });

      setNoteForBillingLocal(NewNoteForBilling);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao alterar Nota de Faturamento.",
        "error"
      );
    }
  };

  React.useEffect(() => {
    if (user > 0) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [user]);

  const handlePasteAllocatedHours = e => {
    handleAllocatedHoursChange(e);
  };

  const handlePasteLoadedMinutesHours = e => {
    handleLoadedHoursChange(e);
  };

  const allowedAddLine = () => {
    const projectListTmp = _.cloneDeep(projectListLocal);

    const totalMinutes = projectListTmp[0].totalMinutes;

    const allocatedMinutes = projectListTmp[0].loadedMinutesList.reduce(
      (sum, item) => {
        return sum + transformHoursToMinutes(item.allocatedMinutesHoursFormat);
      },
      0
    );

    return allocatedMinutes >= totalMinutes;
  };

  return (
    <>
      <Loading isLoading={loading} />
      <StyledModal
        show={showModal && !loading}
        onHide={() => close()}
        size="lg"
        centered
      >
        <Modal.Body>
          <h1 className="modal-title">
            <strong>{title}</strong>
          </h1>

          <Form>
            <Container>
              <Form.Group as={Col} lg="12" xs="12">
                <InputField
                  type="text"
                  name="noteForBillingLocal"
                  label="Obs"
                  placeholder="Digite a Observação"
                  value={noteForBillingLocal}
                  onChange={handleObservationChange}
                />
              </Form.Group>
              {/* Atividade do Profissional */}

              {projectListLocal?.length > 0 &&
                projectListLocal.map((projectItem, index) => (
                  <div key={projectItem.idProject + "_" + index}>
                    {index === 0 ||
                    projectItem.period !==
                      projectListLocal[index - 1].period ? (
                      <Form.Row>
                        <Form.Group as={Col} lg="4" xs="12">
                          <Period>
                            <div>
                              Período:{" "}
                              <strong>
                                {GetPeriodName(projectItem.period)}
                              </strong>
                            </div>
                          </Period>
                        </Form.Group>
                      </Form.Row>
                    ) : null}

                    <Form.Row>
                      <TitlePrj>
                        <div className="project">
                          <h2>
                            Projeto:{" "}
                            <strong>{projectItem.name.slice(0, 40)}</strong>
                          </h2>
                        </div>
                        <div className="time">
                          <h3>
                            H.A.:{" "}
                            <strong
                              style={{
                                color:
                                  projectItem.totalAllocatedMinutes <
                                  projectItem.totalMinutes
                                    ? "#ff504d"
                                    : "inherit"
                              }}
                            >
                              {transformMinutesToHours(
                                projectItem.totalAllocatedMinutes
                              )}
                            </strong>
                            &nbsp; H.C.:{" "}
                            <strong
                              style={{
                                color:
                                  projectItem.totalLoadedMinutes <
                                  projectItem.totalMinutes
                                    ? "#ff504d"
                                    : "inherit"
                              }}
                            >
                              {transformMinutesToHours(
                                projectItem.totalLoadedMinutes
                              )}
                            </strong>
                            &nbsp; T.H:{" "}
                            <strong>
                              {transformMinutesToHours(
                                projectItem.totalMinutes
                              )}
                            </strong>
                            <StyledIconButton
                              disabled={allowedAddLine()}
                              onClick={e =>
                                handleAddFieldsInProject(
                                  e,
                                  projectItem.idProject
                                )
                              }
                            >
                              <StyledPlusIcon />
                            </StyledIconButton>
                          </h3>
                        </div>
                      </TitlePrj>
                    </Form.Row>

                    {projectItem.loadedMinutesList?.length > 0 &&
                      projectItem.loadedMinutesList.map(
                        (loadedMinutesItem, index) => (
                          <Form.Row key={index}>
                            <Fields>
                              <Form.Group as={Col} lg="1" xs="8">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center"
                                  }}
                                >
                                  {index !== 0 && (
                                    <StyledTrashIcon
                                      onClick={() =>
                                        handleDeleteLoadedMinutesItem(
                                          loadedMinutesItem
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </Form.Group>
                              <Form.Group as={Col} lg="2" xs="12">
                                <InputField
                                  type="text"
                                  name={
                                    "demand;;" +
                                    projectItem.idProject +
                                    ";;" +
                                    loadedMinutesItem.idx +
                                    ";;" +
                                    projectItem.period
                                  }
                                  label="JIRA"
                                  placeholder="Digite a Demanda"
                                  value={loadedMinutesItem.demand}
                                  onChange={handleDemandChange}
                                  maxLength={50}
                                  required
                                />
                              </Form.Group>

                              <Form.Group as={Col} lg="3" xs="12">
                                <InputField
                                  type="text"
                                  name={
                                    "payingArea;;" +
                                    projectItem.idProject +
                                    ";;" +
                                    loadedMinutesItem.idx +
                                    ";;" +
                                    projectItem.period
                                  }
                                  label="Área Pagadora"
                                  placeholder="Digite a Área Pagadora"
                                  value={loadedMinutesItem.payingAreaName}
                                  onChange={handlePayingAreaChange}
                                  maxLength={100}
                                  required
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                lg="3"
                                xs="12"
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <InputField
                                  type="text"
                                  as={InputMask}
                                  mask="99:99:99"
                                  name={
                                    "hAlocada;;" +
                                    projectItem.idProject +
                                    ";;" +
                                    loadedMinutesItem.idx +
                                    ";;" +
                                    projectItem.period
                                  }
                                  label="H.Alocada"
                                  placeholder="00:00:00"
                                  value={
                                    loadedMinutesItem.allocatedMinutesHoursFormat
                                  }
                                  onChange={handleAllocatedHoursChange}
                                  onBlur={handleAllocatedHoursBlur}
                                  required
                                />
                                <StyledPasteIcon
                                  onClick={() =>
                                    handlePasteAllocatedHours({
                                      target: {
                                        name:
                                          "hAlocada;;" +
                                          projectItem.idProject +
                                          ";;" +
                                          loadedMinutesItem.idx +
                                          ";;" +
                                          projectItem.period,
                                        value: transformMinutesToHours(
                                          projectItem.totalMinutes
                                        )
                                      }
                                    })
                                  }
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                lg="3"
                                xs="12"
                                style={{
                                  display: "flex",
                                  alignItems: "center"
                                }}
                              >
                                <InputField
                                  type="text"
                                  as={InputMask}
                                  mask="99:99:99"
                                  name={
                                    "hCarregada;;" +
                                    projectItem.idProject +
                                    ";;" +
                                    loadedMinutesItem.idx +
                                    ";;" +
                                    projectItem.period
                                  }
                                  label="H.Carregada"
                                  placeholder="00:00:00"
                                  value={
                                    loadedMinutesItem.loadedMinutesHoursFormat
                                  }
                                  onChange={handleLoadedHoursChange}
                                  onBlur={handleLoadedHoursBlur}
                                  required
                                />
                                <StyledPasteIcon
                                  onClick={() =>
                                    handlePasteLoadedMinutesHours({
                                      target: {
                                        name:
                                          "hCarregada;;" +
                                          projectItem.idProject +
                                          ";;" +
                                          loadedMinutesItem.idx +
                                          ";;" +
                                          projectItem.period,
                                        value: transformMinutesToHours(
                                          projectItem.totalMinutes
                                        )
                                      }
                                    })
                                  }
                                />
                              </Form.Group>
                            </Fields>
                          </Form.Row>
                        )
                      )}
                    <hr></hr>
                  </div>
                ))}
            </Container>

            <div className="btn-container">
              <Button type="button" onClick={close}>
                CANCELAR
              </Button>
              <Button
                type="button" //aqui se mudarmos para submit ao clicar ele irá dar reload na pagina.
                onClick={e => {
                  handleModalConfirm();
                }}
              >
                CONFIRMAR
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};
