import React, { useEffect } from "react";
import { Form, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { connect } from "react-redux";
import { InexApiService } from "../../../../services/InexApiService";
import FormActions from "../../components/FormActions";
import Loading from "../../../home/components/Loading";
import { InputField } from "../../components/InputField";
import { StyledCardBody } from "../../components/AdminCard/style";
import { FiCamera, FiEdit3, FiX } from "react-icons/fi";
import IsValidName from "../../../../utils/validators/IsValidName";
import IsValidEmail from "../../../../utils/validators/IsValidEmail";
import IsValidPhone from "../../../../utils/validators/IsValidPhone";
import IsValidDataRange from "../../../../utils/validators/IsValidDataRange";
import formatTel from "../../../../utils/FormatTel";
import { AdminTitle } from "../../components/AdminTitle";
import avatarPlaceholder from "../../../../assets/images/avatar-placeholder.png";
import formatDocument from "../../../../utils/FormatDocument";
import verifyCpfCnpj, {
  formatCPF
} from "../../../../utils/validators/IsValidCpfCnpj";
import { validateFileType } from "../../../../utils/validators/ValidateFileType";
import { ERoles } from "../../../../enums/Roles";
import { TranslateProfileType } from "../../../../utils/TranslateProfileType";
import formatCurrency from "../../../../utils/FormatCurrency";

const initialState = {
  id: 0,

  profile: "",
  RFQ: { value: "", label: "" },
  gerente: "",
  regimeContratacao: "",
  matriculaInex: "",
  matriculaCliente: "",
  alocacoes: [],
  alocacoesProjetos: [],
  propostaTecnica: "",
  avatar: null,
  currentUploadedAvatar: null,
  removeAvatar: false,
  historico: "",

  nome: "",
  CPF: "",
  RG: "",
  celular: "",
  inexEmail: "",
  personalEmail: "",
  /* salarioFixo: "", */
  dataNascimento: "",
  dataAdmissao: "",
  dataRescisao: "",

  CNPJ: "",
  razaoSocial: "",
  valorHoraDefault: "",
  /* valorHora: "", */
  contratoSemanal: false,
  showReceiveTotal: false,
  persistedModel: "",
  resetInput: false,
  isLoading: false,
  lastPage: 3,
  page: 1
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.userAvatar = React.createRef();
    this.state = {
      ...initialState,
      profiles: [],
      RFQS: [],
      regimesOptions: [],
      gerentesOptions: [],
      operationsManagersOptions: [],
      clientesOptions: [],
      contratoSemanal: false,
      allowEditEntollment: false,
      profileLogged: "",
      userLogged: "",
      disableProfile: false,
      isEditing: false
    };

    this.api = new InexApiService();

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeRFQ = this.handleChangeRFQ.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeRangedate = this.handleChangeRangedate.bind(this);
    this.handleChangeAllocations = this.handleChangeAllocations.bind(this);
    this.onCleanForm = this.onCleanForm.bind(this);
    this.handleUserAvatar = this.handleUserAvatar.bind(this);
    this.handleRemoveAvatar = this.handleRemoveAvatar.bind(this);
    this.loadUser = this.loadUser.bind(this);
    this.loadProfiles = this.loadProfiles.bind(this);
    this.loadEnrollment = this.loadEnrollment.bind(this);
    this.loadRegimes = this.loadRegimes.bind(this);
    this.loadGerentes = this.loadGerentes.bind(this);
    this.loadOperationsManagers = this.loadOperationsManagers.bind(this);
    this.loadClientes = this.loadClientes.bind(this);
    this.submit = this.submit.bind(this);
  }

  async componentDidMount() {
    const isEditing = !!this.props.match.params.id;
    const storageAuth = JSON.parse(localStorage.getItem("persist:auth:inex"));
    const user = JSON.parse(storageAuth.user);
    this.setState({
      isLoading: true,
      isEditing,
      profileLogged: user.role.type,
      userLogged: user
    });

    try {
      const reqs = [
        this.loadEnrollment(),
        this.loadProfiles(),
        this.loadRegimes(),
        this.loadGerentes(),
        this.loadOperationsManagers(),
        this.loadClientes()
      ];

      if (this.props.match.params.id) {
        reqs.push(this.loadUser(this.props.match.params.id));
        reqs.shift();
      } else {
        this.loadRFQS();
      }

      await Promise.all(reqs);
    } catch (error) {
      Swal.fire(
        "Erro!",
        error?.response
          ? error?.response?.data
          : "Ocorreu um erro ao carregar os dados, tente novamente mais tarde!",
        "error"
      );
    }

    this.setState({ isLoading: false });
  }

  loadUser = async id => {
    const user = await this.api.makeHttpRequest({
      url: `/professional/${id}`
    });

    const formattedAllocations =
      user.clients?.length > 0
        ? user.clients.map(c => ({ label: c.name, value: c.idClient }))
        : [];

    this.loadRFQSByClients(
      user.clients.map(c => c.idClient),
      user.idRFQ
    );

    const persistedModel = {
      id: id,

      profile: user.role.type,
      regimeContratacao: `${user.contractType}`,
      matriculaInex: user.inexEnrollment,
      matriculaCliente: user.clientEnrollment ? user.clientEnrollment : "",
      propostaTecnica: user.technicalProposal ? user.technicalProposal : "",
      gerente: user.commercial.idUser,
      alocacoes: formattedAllocations,
      alocacoesProjetos: user.projects,
      currentUploadedAvatar: user.profilePictureURL,
      historico: user.history,

      nome: user.name,
      CPF: user.cpf,
      RG: user.rg,
      celular: user.phone,
      inexEmail: user.inexEmail,
      personalEmail: user.personalEmail ? user.personalEmail : "",
      /* salarioFixo: user.wage ? user.wage : "", */
      dataNascimento: moment(user.birthdate).toDate(),
      dataAdmissao: moment(user.admissionDate).toDate(),
      dataRescisao: user.terminationDate
        ? moment(user.terminationDate).toDate()
        : "",
      CNPJ: user.cnpj ?? "",
      razaoSocial: user.fantasyName ?? "",
      contratoSemanal: user.yct ?? false,
      showReceiveTotal: user.showReceiveTotal ?? false,
      valorHoraDefault: user.wageHourDefault
        ? formatCurrency(user.wageHourDefault.toFixed(2))
        : ""
      /* valorHora: user.wageHour ? user.wageHour : "" */
    };

    const persistedModelJson = JSON.stringify(persistedModel);

    this.setState({
      ...persistedModel,
      isLoading: false,
      resetInput: false,
      lastPage: user.contractType === 0 ? 3 : 4,
      persistedModel: persistedModelJson
    });
  };

  loadProfiles = async () => {
    const profiles = await this.api.makeHttpRequest({
      url: "/professional/roles"
    });

    this.setState({
      profiles
    });

    if (profiles.length === 1) {
      this.setState({
        profile: profiles.type,
        disableProfile: true
      });
    }
  };

  loadRFQSByClients = async (idClients, idRFQ) => {
    let idClientsQuery = idClients
      ? "?" + idClients.map(id => "idClients=" + id).join("&")
      : "";
    let endpoint = `/professional/all-rfq-by-clients${idClientsQuery}`;
    const RFQS = await this.api.makeHttpRequest({
      url: endpoint
    });

    const formattedProposal =
      RFQS.length > 0
        ? RFQS.map(prop => ({
            label: prop.profileDescription,
            value: prop.idBusinessProposalValue
          }))
        : [];

    console.log(formattedProposal);

    let rfq = idRFQ
      ? formattedProposal.find(p => p.value === idRFQ)
      : undefined;

    this.setState({
      RFQS: formattedProposal,
      RFQ: { value: rfq ? rfq.value : "", label: rfq ? rfq.label : "" }
    });
  };

  loadRFQS = async () => {
    const RFQS = await this.api.makeHttpRequest({
      url: "/professional/all-rfq"
    });

    const formattedProposal =
      RFQS.length > 0
        ? RFQS.map(prop => ({
            label: prop.profileDescription,
            value: prop.idBusinessProposalValue
          }))
        : [];

    this.setState({
      RFQS: formattedProposal
    });
  };

  loadEnrollment = async () => {
    const response = await this.api.makeHttpRequest({
      url: "/professional/enrollment"
    });

    this.setState({
      matriculaInex: response + 1 ?? ""
    });
  };

  loadRegimes = async () => {
    const regimes = await this.api.makeHttpRequest({
      url: "/professional/regimes"
    });

    this.setState({
      regimesOptions: regimes ?? []
    });
  };

  loadGerentes = async () => {
    const gerentes = await this.api.makeHttpRequest({
      url: "/professional/commercial-managers"
    });

    this.setState({
      gerentesOptions: gerentes
    });
  };

  loadOperationsManagers = async () => {
    const operationsManagers = await this.api.makeHttpRequest({
      url: "/professional/operations-managers"
    });

    this.setState({
      operationsManagersOptions: operationsManagers
    });
  };

  loadClientes = async () => {
    const clientes = await this.api.makeHttpRequest({
      url: "/professional/clients"
    });

    const formattedOptions =
      clientes.length > 0
        ? clientes.map(c => ({ label: c.name, value: c.idClient }))
        : [];

    this.setState({
      clientesOptions: formattedOptions
    });
  };

  previousPage = () => {
    const { page } = this.state;
    if (page > 1) {
      this.setState({ page: page - 1 });
    }
  };

  nextPage = () => {
    const { page, lastPage } = this.state;
    if (page < lastPage) {
      this.setState({ page: page + 1 });
    }
  };

  submit = async e => {
    e.preventDefault();

    const id = this.props.match.params.id;
    this.setState({ isLoading: true });
    try {
      const reqBase = id
        ? { method: "PUT", url: `/professional/update/${id}` }
        : { method: "POST", url: "/professional/save" };

      const rawPhone =
        this.state.celular && this.state.celular.replace(/\D/g, "");
      const rawCpf = this.state.CPF && this.state.CPF.replace(/\D/g, "");
      const rawCnpj = this.state.CNPJ ? this.state.CNPJ.replace(/\D/g, "") : "";

      const bodyFormData = new FormData();
      bodyFormData.append("role[type]", this.state.profile);
      bodyFormData.append("IdRFQ", this.state.RFQ.value);
      bodyFormData.append("inexEnrollment", this.state.matriculaInex);
      bodyFormData.append("clientEnrollment", this.state.matriculaCliente);
      bodyFormData.append("contractType", this.state.regimeContratacao);
      bodyFormData.append("technicalProposal", this.state.propostaTecnica);
      bodyFormData.append("commercial[idUser]", this.state.gerente);
      bodyFormData.append("name", this.state.nome);
      bodyFormData.append(
        "birthdate",
        moment(this.state.dataNascimento).format()
      );
      bodyFormData.append("CPF", rawCpf);
      bodyFormData.append("RG", this.state.RG);
      bodyFormData.append("inexEmail", this.state.inexEmail);
      bodyFormData.append("personalEmail", this.state.personalEmail);
      bodyFormData.append("phone", rawPhone);
      bodyFormData.append(
        "admissionDate",
        moment(this.state.dataAdmissao).format()
      );
      bodyFormData.append(
        "terminationDate",
        this.state.dataRescisao ? moment(this.state.dataRescisao).format() : ""
      );
      /* bodyFormData.append("wage", this.state.salarioFixo ?? ""); */
      bodyFormData.append("CNPJ", rawCnpj);
      bodyFormData.append("fantasyName", this.state.razaoSocial ?? "");
      /* bodyFormData.append("wageHour", this.state.valorHora ?? ""); */
      bodyFormData.append(
        "wageHourDefault",
        this.state.valorHoraDefault
          ? parseFloat(
              this.state.valorHoraDefault
                .replaceAll(".", "")
                .replaceAll(",", ".")
            )
          : ""
      );
      bodyFormData.append("profilePicture.File", this.state.avatar);
      bodyFormData.append("removeAvatar", this.state.removeAvatar);
      bodyFormData.append("History", this.state.historico ?? "");
      if (this.state.alocacoes?.length) {
        this.state.alocacoes.forEach((client, index) => {
          bodyFormData.append(`Clients[${index}][IdClient]`, client.value);
        });
      }
      bodyFormData.append("weeklyContract", this.state.contratoSemanal);
      bodyFormData.append("showReceiveTotal", this.state.showReceiveTotal);

      await this.api.makeHttpRequest({
        ...reqBase,
        data: bodyFormData
      });

      this.afterSubmit();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao cadastrar usuário, tente novamente mais tarde.",
        "error"
      );
    }

    this.setState({ isLoading: false });
  };

  afterSubmit = async () => {
    this.setState({
      ...initialState,
      resetInput: true
    });

    const { isEditing } = this.state;

    const { value } = await Swal.fire({
      title: "Sucesso!",
      text: "Dados salvos com sucesso.",
      icon: "success",
      showCancelButton: !isEditing,
      confirmButtonText: isEditing ? "Ok" : "Novo cadastro",
      cancelButtonText: "Sair"
    });

    if (!value || isEditing) {
      this.props.history.push("/admin/profissionais");
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    if (name === "regimeContratacao") {
      if (e.target.value === "0") {
        this.setState({
          lastPage: 3
        });
      } else {
        this.setState({
          lastPage: 4
        });
      }
    }

    let valueFormated = e.target.value;
    if (name === "valorHoraDefault") {
      valueFormated = formatCurrency(value);
    }

    if (name == "dataNascimento") {
      if (!this.handleCalculateMinAge(new Date(value))) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Data de Nascimento inválida.",
          confirmButtonColor: "#4672C5",
          confirmButtonText: "OK"
        });
        return;
      }
    }

    if (name === "dataRescisao") {
      const startDate = new Date(this.state.dataAdmissao);
      const endDate = new Date(value);

      const differenceDate = endDate - startDate;

      if (differenceDate < 0) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Data de Rescisão não pode ser anterior à Data de Admissão!",
          confirmButtonColor: "#4672C5",
          confirmButtonText: "OK"
        });

        return;
      }
    }

    this.setState({
      [e.target.name]: valueFormated
    });
  };

  handleChangeName = e => {
    const name = e.target.value;

    const words = name.split(" ");
    // Capitaliza a primeira letra de cada nome e mantém as preposições em minúsculas
    const capitalizedWords = words.map(word => {
      if (["Da", "Do", "Dos", "Das", "da", "do", "dos", "das"].includes(word)) {
        return word.toLowerCase();
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
    });

    // Junta as palavras novamente em uma única string
    const capitalizedName = capitalizedWords.join(" ");

    this.setState({
      [e.target.name]: capitalizedName
    });
  };

  handleChangeRangedate = e => {
    const isValid = IsValidDataRange(e.target.value);
    if (!isValid) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Data de admissão não pode ser superior a 30 dias da data atual",
        confirmButtonColor: "#4672C5",
        confirmButtonText: "OK"
      });

      return;
    }
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleCalculateMinAge = birthday => {
    const currentDate = new Date();

    let age = currentDate.getFullYear() - birthday.getFullYear();

    const monthBirthday = birthday.getMonth();
    const dayBirthday = birthday.getDate();
    const currentMonth = currentDate.getMonth();
    const dayCurrent = currentDate.getDate();

    if (
      currentMonth < monthBirthday ||
      (currentMonth === monthBirthday && dayCurrent < dayBirthday)
    ) {
      age--;
    }

    console.log(age);

    return age >= 16 && age <= 80;
  };

  handleChangeRFQ = (e, select2Event) => {
    if (select2Event?.name === "RFQ") {
      return this.setState({
        RFQ: e
      });
    }
  };

  handleChangeAllocations = (value, event) => {
    const { alocacoesProjetos } = this.state;

    const alocacoes = event.removedValue
      ? alocacoesProjetos.filter(x => x.idClient === event.removedValue.value)
      : [...alocacoesProjetos];

    let projetos =
      alocacoes?.length > 0 ? alocacoes.map(proj => proj.projectName) : [];

    if (
      projetos.length > 0 &&
      (event.action === "remove-value" || event.action === "clear")
    ) {
      Swal.fire({
        title: "Aviso!",
        html: `Ao salvar, este profissional também será removido dos seguintes projetos: <strong>${projetos
          .toString()
          .replaceAll(",", ", ")}</strong>`,
        icon: "warning"
      });
    }
    if (value) {
      this.loadRFQSByClients(
        value.map(v => v.value),
        this.state.RFQ.value
      );
    }
    this.setState({ alocacoes: value });
  };

  handleUserAvatar = () => {
    if (this.fileInput.current && this.fileInput.current.files[0]) {
      const isValid = validateFileType(this.fileInput.current.value);
      if (!isValid) {
        Swal.fire({
          icon: "error",
          title: "Tipo de arquivo inválido",
          text: "Apenas arquivos JPEG/PNG/JPG",
          confirmButtonColor: "#4672C5",
          confirmButtonText: "OK"
        });

        return;
      }

      const file = new FileReader();
      file.onload = e => {
        this.userAvatar.current.src = e.target.result;
      };
      file.readAsDataURL(this.fileInput.current.files[0]);
      this.setState({
        avatar: this.fileInput.current.files[0],
        currentUploadedAvatar: this.fileInput.current.files[0]
      });
    }
  };

  handleRemoveAvatar = async () => {
    const isForRemove = await Swal.fire({
      showCancelButton: true,
      icon: "warning",
      title: "Remover avatar",
      text: "Você tem certeza que quer remover seu avatar?",
      confirmButtonColor: "#4672C5",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    });

    if (isForRemove.dismiss) {
      return;
    }

    if (isForRemove.value) {
      if (this.fileInput.current?.files[0]) {
        this.userAvatar.current.src = "";
        this.fileInput.current.value = "";
      }

      this.setState({
        avatar: null,
        currentUploadedAvatar: "",
        removeAvatar: true
      });
    }
  };

  formIsValid = () => {
    const { regimeContratacao, isEditing, persistedModel } = this.state;

    const currentModel = {
      id: this.state.id,

      profile: this.state.profile,
      RFQ: this.state.RFQ,
      regimeContratacao: this.state.regimeContratacao,
      matriculaInex: this.state.matriculaInex,
      matriculaCliente: this.state.matriculaCliente,
      propostaTecnica: this.state.propostaTecnica,
      gerente: this.state.gerente,
      alocacoes: this.state.alocacoes,
      alocacoesProjetos: this.state.alocacoesProjetos,
      currentUploadedAvatar: this.state.currentUploadedAvatar,
      historico: this.state.historico,

      nome: this.state.nome,
      CPF: this.state.CPF,
      RG: this.state.RG,
      celular: this.state.celular,
      inexEmail: this.state.inexEmail,
      personalEmail: this.state.personalEmail,
      /* salarioFixo: this.state.salarioFixo, */
      dataNascimento: this.state.dataNascimento,
      dataAdmissao: this.state.dataAdmissao,
      dataRescisao: this.state.dataRescisao,

      CNPJ: this.state.CNPJ,
      razaoSocial: this.state.razaoSocial,
      valorHoraDefault: this.state.valorHoraDefault
      /* valorHora: this.state.valorHora */
    };

    const currentModelJson = JSON.stringify(currentModel);

    const validFirst =
      !!this.state.profile &&
      !!this.state.regimeContratacao &&
      !!this.state.matriculaInex &&
      this.state.alocacoes?.length > 0 &&
      !!this.state.gerente &&
      (isEditing ? currentModelJson !== persistedModel : true);

    const validPf =
      validFirst &&
      !!this.state.nome &&
      IsValidName(this.state.nome) &&
      !!this.state.dataNascimento &&
      !!this.state.CPF &&
      this.state.CPF.replace(/\D/g, "").length === 11 &&
      verifyCpfCnpj(this.state.CPF).isValid &&
      !!this.state.RG &&
      !!this.state.dataAdmissao &&
      !!this.state.celular &&
      IsValidPhone(this.state.celular) &&
      !!this.state.inexEmail &&
      IsValidEmail(this.state.inexEmail) &&
      (this.state.personalEmail
        ? IsValidEmail(this.state.personalEmail)
        : true);

    const validPj =
      validPf &&
      !!this.state.CNPJ &&
      this.state.CNPJ.replace(/\D/g, "").length === 14 &&
      verifyCpfCnpj(this.state.CNPJ).isValid &&
      !!this.state.razaoSocial &&
      IsValidName(this.state.razaoSocial);

    return regimeContratacao === "0" ? validPf : validPj;
  };

  onCleanForm = async () => {
    if (this.props.match.params.id) {
      this.setState({ isLoading: true });

      try {
        await this.loadUser(this.props.match.params.id);
      } catch (e) {
        Swal.fire(
          "Erro",
          "Problema ao reverter as alterações, tente mais tarde",
          "error"
        );
      }

      this.setState({ isLoading: false });
    } else {
      this.setState({
        ...initialState,
        resetInput: true
      });
    }
  };

  render() {
    if (this.state.isEditing) {
      window.setPageTitle("Atualizar Usuário - Admin");
    } else {
      window.setPageTitle("Cadastro de Usuário - Admin");
    }

    const {
      id,

      profile,
      RFQ,
      gerente,
      regimeContratacao,
      matriculaInex,
      matriculaCliente,
      alocacoes,
      propostaTecnica,
      currentUploadedAvatar,
      historico,

      nome,
      CPF,
      RG,
      celular,
      inexEmail,
      personalEmail,
      /* salarioFixo, */
      dataNascimento,
      dataAdmissao,
      dataRescisao,

      CNPJ,
      razaoSocial,
      valorHoraDefault,
      /* valorHora, */
      regimesOptions,
      gerentesOptions,
      operationsManagersOptions,
      clientesOptions,
      allowEditEntollment,

      profileLogged,
      profiles,
      RFQS,
      contratoSemanal,
      showReceiveTotal,
      userLogged,
      disableProfile,
      isEditing,
      isLoading,
      lastPage,
      page
    } = this.state;

    const firstPage = (
      <StyledCardBody>
        <div className="avatar">
          <input
            type="file"
            accept="image/*"
            name="avatar"
            ref={this.fileInput}
            onChange={this.handleUserAvatar}
            className="file-input"
          />
          {currentUploadedAvatar ? (
            <>
              <div className="remove-button" onClick={this.handleRemoveAvatar}>
                <FiX size="25px" />
              </div>
              <img
                src={currentUploadedAvatar}
                alt="Foto de perfil"
                ref={this.userAvatar}
              />
            </>
          ) : (
            <img src={avatarPlaceholder} alt="Avatar" />
          )}
          <div
            className="upload-button"
            onClick={() => this.fileInput.current.click()}
          >
            <FiCamera size="18px" />
          </div>
        </div>
        <Form className="form-admin">
          {/* row 1 */}
          <h1 className="card-title">Identificação</h1>
          <Form.Row>
            {/* Nome */}
            <Form.Group as={Col} lg="8" xs="12">
              <InputField
                type="text"
                name="nome"
                label="Nome"
                value={nome}
                placeholder="Digite o nome"
                onChange={this.handleChangeName}
                isInvalid={nome && !IsValidName(nome)}
                maxLength={100}
                required
              />
            </Form.Group>
            {/* Perfil */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                as="select"
                name="profile"
                label="Perfil"
                value={profile}
                disabled={
                  (profileLogged !== ERoles.ADMIN && isEditing) ||
                  (profileLogged !== ERoles.ADMIN && userLogged.id !== id) ||
                  disableProfile
                }
                onChange={this.handleChange}
                required
              >
                <option value="">Selecione o perfil</option>
                {profiles?.length > 0 &&
                  profiles.map(p => (
                    <option key={p.idRole} value={p.type}>
                      {TranslateProfileType(p.type)}
                    </option>
                  ))}
              </InputField>
            </Form.Group>
          </Form.Row>

          {/* row 2 */}
          <Form.Row>
            {/* Regime de Contratação */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                as="select"
                name="regimeContratacao"
                label="Regime de Contratação"
                value={regimeContratacao}
                onChange={this.handleChange}
                required
              >
                <option value="">Selecione o regime de contratação</option>
                {regimesOptions?.length > 0 &&
                  regimesOptions.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.type}
                    </option>
                  ))}
              </InputField>
            </Form.Group>

            {/* Matricula Inex */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="matriculaInex"
                label="Matricula Inex"
                value={matriculaInex}
                placeholder="Digite o número de matricula"
                onChange={this.handleChange}
                disabled={isEditing || !allowEditEntollment}
                endAdornment={
                  !isEditing && (
                    <div
                      title="Toggle edição da matrícula"
                      onClick={() =>
                        this.setState({
                          allowEditEntollment: !allowEditEntollment
                        })
                      }
                    >
                      {allowEditEntollment ? (
                        <FiEdit3 color="#4672C5" size="20px" />
                      ) : (
                        <FiEdit3 color="#807E80" size="20px" />
                      )}
                    </div>
                  )
                }
                required
              />
            </Form.Group>
            {/* Matricula Cliente */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="matriculaCliente"
                label="Matricula Cliente"
                value={matriculaCliente}
                placeholder="Digite a matricula"
                onChange={this.handleChange}
                maxLength={50}
              />
            </Form.Group>
          </Form.Row>

          {/* row 3 */}
          <Form.Row>
            {/* Gerente Comercial */}
            <Form.Group as={Col} lg="8" xs="12">
              <InputField
                as="select"
                name="gerente"
                label="Gerente Comercial"
                value={gerente}
                onChange={this.handleChange}
                required
              >
                <option value="">Selecione o gestor</option>
                {gerentesOptions?.length > 0 &&
                  gerentesOptions.map(p => (
                    <option key={p.idUser} value={p.idUser}>
                      {p.name}
                    </option>
                  ))}
              </InputField>
            </Form.Group>

            {/* Proposta Técnica */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="propostaTecnica"
                label="Proposta Técnica"
                value={propostaTecnica}
                onChange={this.handleChange}
                placeholder="Digite o número da proposta técnica"
                maxLength={100}
              />
            </Form.Group>
          </Form.Row>

          {/* Row 4 */}
          <Form.Row>
            {/* Alocações */}
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                isMulti
                as="select2"
                name="alocacoes"
                label="Alocações Clientes"
                value={alocacoes}
                onChange={this.handleChangeAllocations}
                placeholder="Selecione as empresas"
                options={clientesOptions}
                required
              />
            </Form.Group>

            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                as="select2"
                name="RFQ"
                label="RFQ"
                value={RFQ}
                onChange={this.handleChangeRFQ}
                placeholder="Selecione a RFQ"
                options={RFQS}
                closeMenuOnSelect={true}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </StyledCardBody>
    );

    const casoPessoaFisica = (
      <StyledCardBody>
        <Form className="form-admin">
          {/* row 1 */}
          <h1 className="card-title">Dados Pessoais</h1>
          <Form.Row>
            {/* CPF */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                type="text"
                name="CPF"
                label="CPF"
                value={formatCPF(CPF)}
                maxLength={14}
                placeholder="Digite o CPF"
                onChange={this.handleChange}
                isInvalid={
                  CPF &&
                  (!verifyCpfCnpj(CPF).isValid ||
                    CPF.replace(/\D/g, "").length !== 11)
                }
                required
              />
            </Form.Group>

            {/* RG */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                type="text"
                name="RG"
                label="RG"
                value={formatDocument(RG.toUpperCase(), "RG")}
                placeholder="Digite o RG"
                onChange={this.handleChange}
                maxLength={15}
                required
              />
            </Form.Group>

            {/* Nascimento */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                type="date"
                name="dataNascimento"
                label="Nascimento"
                value={dataNascimento}
                placeholder="Selecione a data"
                onChange={this.handleChange}
                required
              />
            </Form.Group>

            {/* Naturalidade */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                type="text"
                name="naturalidade"
                label="Naturalidade"
                value=""
                placeholder="Digite o naturalidade"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Sexo */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                as="select"
                name="sexo"
                label="Sexo"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione o sexo</option>
              </InputField>
            </Form.Group>

            {/* Estado Civil */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                as="select"
                name="sexo"
                label="Estado Civil"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione o estado civil</option>
              </InputField>
            </Form.Group>
          </Form.Row>

          {/* row 2 */}
          <Form.Row>
            {/* Aprendiz */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                as="select"
                name="aprendiz"
                label="Aprendiz"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione a opção</option>
              </InputField>
            </Form.Group>

            {/* Deficiência */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                as="select"
                name="deficiencia"
                label="Deficiência"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione a opção</option>
              </InputField>
            </Form.Group>

            {/* PIS */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                type="text"
                name="PIS"
                label="PIS"
                value=""
                placeholder="Digite o PIS"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Admissão */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                type="date"
                name="dataAdmissao"
                label="Admissão"
                value={dataAdmissao}
                placeholder="Selecione a data"
                onChange={this.handleChangeRangedate}
                required
              />
            </Form.Group>

            {/* Rescisão */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                type="date"
                name="dataRescisao"
                label="Rescisão"
                value={dataRescisao}
                placeholder="Selecione a data"
                onChange={this.handleChange}
                disabled={!dataAdmissao}
                canDelete={!this.state.id}
              />
            </Form.Group>
          </Form.Row>

          {/* row 3 */}
          <Form.Row>
            {/* CEP */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                type="text"
                name="CEP"
                label="CEP"
                value=""
                placeholder="Digite o CEP"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Estado */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                as="select"
                name="estado"
                label="Estado"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione o estado</option>
              </InputField>
            </Form.Group>

            {/* Cidade */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                as="select"
                name="cidade"
                label="Cidade"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione a cidade</option>
              </InputField>
            </Form.Group>
          </Form.Row>

          {/* row 4 */}
          <Form.Row>
            {/* Logradouro */}
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                className="fade"
                type="text"
                name="logradouro"
                label="Logradouro"
                value=""
                placeholder="Digite o logradouro (rua, avenida, praça etc)"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Complemento */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                type="text"
                name="complemento"
                label="Complemento"
                value=""
                placeholder="Digite o complemento"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Bairro */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                type="text"
                name="bairro"
                label="Bairro"
                value=""
                placeholder="Digite o bairro"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>
          </Form.Row>

          {/* row 5 */}
          <Form.Row>
            {/* Celular */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                type="tel"
                name="celular"
                label="Celular"
                value={formatTel(celular)}
                maxLength="19"
                placeholder="Digite o celular com DDD"
                onChange={this.handleChange}
                isInvalid={celular && !IsValidPhone(celular)}
                required
              />
            </Form.Group>

            {/* Telefone Residencial */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="tel"
                name="telResid"
                label="Telefone Residencial"
                value=""
                placeholder="Digite o telefone com DDD"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* E-mail (INEX) */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                type="email"
                name="inexEmail"
                label="E-mail Inex (Login)"
                value={inexEmail}
                placeholder="Digite o email"
                onChange={this.handleChange}
                isInvalid={inexEmail && !IsValidEmail(inexEmail)}
                disabled={isEditing}
                maxLength={100}
                required
              />
            </Form.Group>

            {/* E-mail pessoal */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                type="email"
                name="personalEmail"
                label="E-mail Pessoal"
                value={personalEmail}
                placeholder="Digite o email"
                onChange={this.handleChange}
                isInvalid={personalEmail && !IsValidEmail(personalEmail)}
                maxLength={100}
              />
            </Form.Group>
          </Form.Row>

          {/* row 6 */}
          <Form.Row>
            {/* Salário Fixo */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                type="text"
                name="salarioFixo"
                label="Salario Fixo"
                value={"salarioFixo"}
                placeholder="Digite o salário fixo"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Tipo da Conta */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                as="select"
                name="tipoConta"
                label="Tipo da Conta"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione o tipo da conta</option>
              </InputField>
            </Form.Group>

            {/* Número/Nome do Banco */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                as="select"
                name="infoBanco"
                label="Número/Nome do Banco"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione o banco</option>
              </InputField>
            </Form.Group>
          </Form.Row>

          {/* row 7 */}
          <Form.Row>
            {/* Agência */}
            <Form.Group as={Col} lg="1" xs="12">
              <InputField
                className="fade"
                type="text"
                name="agencia"
                label="Agência"
                value=""
                placeholder="Digite o número da agência"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Conta */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                type="tel"
                name="conta"
                label="Conta"
                value=""
                placeholder="Digite o número da conta"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Chave PIX */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                type="tel"
                name="PIX"
                label="Chave PIX"
                value=""
                placeholder="Digite a chave PIX"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Contrato semanal */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                as="select"
                name="contratoSemanal"
                label="Contrato semanal"
                value={contratoSemanal}
                onChange={this.handleChange}
                required
              >
                <option key={"Não"} value={false}>
                  Não
                </option>
                <option key={"Sim"} value={true}>
                  Sim
                </option>
              </InputField>
            </Form.Group>

            {/* Exibir total a receber na tela do timer */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                as="select"
                name="showReceiveTotal"
                label="Exibir total a receber na tela do timer"
                value={showReceiveTotal}
                onChange={this.handleChange}
                required
              >
                <option key={"Não"} value={false}>
                  Não
                </option>
                <option key={"Sim"} value={true}>
                  Sim
                </option>
              </InputField>
            </Form.Group>
            {/* Valor Hora Padrão   */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                type="text"
                name="valorHoraDefault"
                label="Valor Hora Padrão"
                value={valorHoraDefault}
                placeholder="Digite o valor hora padrão"
                onChange={this.handleChange}
                maxLength={21}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </StyledCardBody>
    );

    const casoPessoaJuridica = (
      <StyledCardBody>
        <Form className="form-admin">
          {/* row 1 */}
          <h1 className="card-title">Dados Pessoa Jurídica</h1>
          <Form.Row>
            {/* CNPJ */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                name="CNPJ"
                label="CNPJ"
                value={formatCPF(CNPJ)}
                maxLength={18}
                placeholder="Digite o CNPJ"
                onChange={this.handleChange}
                isInvalid={
                  CNPJ &&
                  (!verifyCpfCnpj(CNPJ).isValid ||
                    CNPJ.replace(/\D/g, "").length < 14)
                }
                required
              />
            </Form.Group>

            {/* Razão Social */}
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                type="text"
                name="razaoSocial"
                label="Razão Social"
                value={razaoSocial}
                placeholder="Digite a razão social"
                onChange={this.handleChange}
                isInvalid={razaoSocial && !IsValidName(razaoSocial)}
                maxLength={100}
                required
              />
            </Form.Group>

            {/* Filial */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                type="text"
                name="filial"
                label="Filial"
                value=""
                placeholder="Digite a filial"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>
          </Form.Row>

          {/* row 2 */}
          <Form.Row>
            {/* CEP */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                type="text"
                className="fade"
                name="CEP"
                label="CEP"
                value=""
                placeholder="Digite o CEP"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Estado */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                as="select"
                name="estado"
                label="Estado"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione o estado</option>
              </InputField>
            </Form.Group>

            {/* Cidade */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                as="select"
                name="cidade"
                label="Cidade"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione a cidade</option>
              </InputField>
            </Form.Group>
          </Form.Row>

          {/* row 3 */}
          <Form.Row>
            {/* Logradouro */}
            <Form.Group as={Col} lg="6" xs="12">
              <InputField
                className="fade"
                type="text"
                name="logradouro"
                label="Logradouro"
                value=""
                placeholder="Digite o logradouro (rua, avenida, praça etc)"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Complemento */}
            <Form.Group as={Col} lg="2" xs="12">
              <InputField
                className="fade"
                type="text"
                name="complemento"
                label="Complemento"
                value=""
                placeholder="Digite o complemento"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Bairro */}
            <Form.Group as={Col} lg="4" xs="12">
              <InputField
                className="fade"
                type="text"
                name="bairro"
                label="Bairro"
                value=""
                placeholder="Digite o bairro"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>
          </Form.Row>

          {/* row 4 */}
          <Form.Row>
            {/* Tomador */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="text"
                name="tomador"
                label="Tomador"
                value=""
                placeholder="Digite o tomador"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Lotação */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="text"
                name="lotacao"
                label="Lotação"
                value=""
                placeholder="Digite a lotação"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Vínculo */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="text"
                name="vinculo"
                label="Vínculo"
                value=""
                placeholder="Digite o vínculo"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Cargo/Função */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="text"
                name="cargo"
                label="Cargo/Função"
                value=""
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>
          </Form.Row>

          {/* row 5 */}
          <Form.Row>
            {/* CBO */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="text"
                name="CBO"
                label="CBO"
                value=""
                placeholder="Digite o CBO"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Registra Ponto */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                as="select"
                name="ponto"
                label="Registra Ponto"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione a opção</option>
              </InputField>
            </Form.Group>

            {/* Valor Hora */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="text"
                name="valorHora"
                label="Valor Hora"
                value={"valorHora"}
                placeholder="Digite o valor hora"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Chave PIX */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="tel"
                name="PIX"
                label="Chave PIX"
                value=""
                placeholder="Digite a chave PIX"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>
          </Form.Row>

          {/* row 6 */}
          <Form.Row>
            {/* Tipo da Conta */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                as="select"
                name="tipoConta"
                label="Tipo da Conta"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione o tipo da conta</option>
              </InputField>
            </Form.Group>

            {/* Número/Nome do Banco */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                as="select"
                name="infoBanco"
                label="Número/Nome do Banco"
                value=""
                onChange={this.handleChange}
                disabled
              >
                <option value="">Selecione o banco</option>
              </InputField>
            </Form.Group>

            {/* Agência */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="text"
                name="agencia"
                label="Agência"
                value=""
                placeholder="Digite o número da agência"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>

            {/* Conta */}
            <Form.Group as={Col} lg="3" xs="12">
              <InputField
                className="fade"
                type="text"
                name="conta"
                label="Conta"
                value=""
                placeholder="Digite o número da conta"
                onChange={this.handleChange}
                disabled
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </StyledCardBody>
    );

    const historicoPage = (
      <StyledCardBody>
        <Form className="form-admin">
          {/* row 1 */}
          <h1 className="card-title">Histórico</h1>
          <Form.Row style={{ height: "fit-content" }}>
            {/* Historico */}
            <Form.Group as={Col} lg="12" xs="12">
              <InputField
                as="textarea"
                name="historico"
                placeholder="Adicione um comentário a este usuário"
                value={historico}
                style={{ height: "500px", marginBottom: "-5px" }}
                onChange={this.handleChange}
                maxLength={5000}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </StyledCardBody>
    );

    const renderByPageNumber = () => {
      // eslint-disable-next-line default-case
      switch (page) {
        case 1:
          return firstPage;
        case 2:
          return casoPessoaFisica;
        case 3:
          if (regimeContratacao === "0") {
            return historicoPage;
          } else {
            return casoPessoaJuridica;
          }
        case 4:
          return historicoPage;
      }
    };

    return (
      <>
        <Loading isLoading={isLoading} />
        <AdminTitle title="Dados do Profissional" disableHeader />
        <Card className="mt-3">{renderByPageNumber()}</Card>
        <FormActions
          onCancel={this.props.history.goBack}
          isEdit={isEditing}
          formIsValid={this.formIsValid()}
          onCleanForm={this.onCleanForm}
          onSubmit={this.submit}
          backButton={page !== 1 ? this.previousPage : null}
          nextButton={this.nextPage}
          lastPage={page === lastPage}
          hideReload
        />
      </>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(UserForm);
