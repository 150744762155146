import { Modal } from "react-bootstrap";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 90%;
    max-width: 662px;
    margin: auto;
  }

  .modal-body {
    padding: 37px 24px 24px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 12px;
    }

    textarea {
      line-height: 150%;
    }

    .last-row {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .time-input {
        flex: 1;

        @media screen and (min-width: 669px) {
          display: flex;
          margin-right: auto;
        }
      }
    }
  }
`;

export const CloseField = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;

  & > :first-child {
    font-size: 1.25rem;
    color: #4d4c4d;
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 24px;
    gap: 14px;

    border: none;
    background: #4672c5;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    color: white;
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;

    svg {
      fill: #fff;
    }

    &:disabled {
      background: #807e80;
      cursor: not-allowed;
    }
  }
`;
