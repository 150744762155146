import { Checkbox } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import { RiSearchLine } from "react-icons/ri";
import iconChecked from "../../../../../../assets/icons/icon-check-fill.svg";
import iconNotChecked from "../../../../../../assets/icons/icon-check-stroke.svg";
import { InputField } from "../../../../components/InputField";
import { StyledModal } from "./styles";
import { InexApiService } from "../../../../../../services/InexApiService";

const ProjectTypesModal = ({
  setProjectTypesSelected,
  projectTypes,
  projectTypesSelected,
  projectSelected
}) => {
  const api = new InexApiService();
  const [modalOpen, setModalOpen] = React.useState(false);

  const [searchbar, setSearchbar] = React.useState("");

  const [projectTypeOpt, setProjectTypeOpt] = React.useState("");
  const [projectTypeList, setProjectTypeList] = React.useState([]);
  const [filteredProjectTypeList, setFilteredProjectTypeList] = React.useState(
    []
  );

  React.useEffect(() => {
    if (projectTypes) {
      setProjectTypeList(projectTypes ?? []);
      setFilteredProjectTypeList(projectTypes ?? []);
      // setProjectTypesSelected(projectTypes?.map(x => x.idProjectType));
    }
  }, [projectTypes]);

  React.useEffect(() => {
    if (projectSelected) {
      changeProjectType(projectSelected);
    }
  }, [projectSelected]);

  const changeProjectType = async idProject => {
    const project = await api.makeHttpRequest({
      url: `/project/${idProject}`
    });
    const projectTypeIds = [project.projectType.idProjectType];
    setProjectTypesSelected(projectTypeIds);
  };

  const handleCheck = projectType => {
    if (projectTypesSelected.includes(projectType)) {
      if (projectTypesSelected.length === 1) return;

      setProjectTypesSelected(
        projectTypesSelected.filter(project => {
          return project !== projectType;
        })
      );
    } else {
      setProjectTypesSelected([...projectTypesSelected, projectType]);
    }
    // const arr = [...projectTypesSelected];
    // const index = arr.indexOf(
    //   arr.find(x => x.idProjectType === projectType.idProjectType)
    // );

    // if (index !== -1) {
    //   if (arr.length === 1) {
    //     // Se for o único tipo de projeto selecionado, não permita desmarcá-lo
    //     return;
    //   }
    //   arr.splice(index, 1);
    // } else {
    //   arr.push(projectType);
    // }
  };

  React.useEffect(() => {
    setProjectTypeOpt(
      projectTypesSelected?.length > 0
        ? projectTypesSelected.length === 1
          ? {
              value: `${projectTypesSelected.length} tipo de projeto selecionado`,
              label: `${projectTypesSelected.length} tipo de projeto selecionado`
            }
          : {
              value: `${projectTypesSelected.length} tipos de projeto selecionados`,
              label: `${projectTypesSelected.length} tipos de projeto selecionados`
            }
        : ""
    );
  }, [projectTypesSelected]);

  const handleSearchbar = value => {
    if (value?.length >= 3) {
      setFilteredProjectTypeList(
        projectTypeList?.filter(x =>
          x.name.toUpperCase().includes(value.trim().toUpperCase())
        )
      );
    } else {
      setFilteredProjectTypeList(projectTypeList);
    }

    setSearchbar(value);
  };

  return (
    <>
      <div onClick={() => setModalOpen(true)}>
        <InputField
          select2ClassName="disabled-normal"
          as="select2"
          value={projectTypeOpt}
          placeholder="Selecione os Tipos de Projeto"
          className={projectTypeList.length === 0 ? "fade" : null}
          disabled
        />
      </div>

      <StyledModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        size="lg"
        centered
        scrollable
      >
        <Modal.Body>
          <div className="searchbar">
            <input
              type="text"
              value={searchbar}
              onChange={e => handleSearchbar(e.target.value)}
              placeholder="Buscar tipo de projeto"
            />
            <span className="icon-search">
              <RiSearchLine size="24px" color="#4672c5" />
            </span>
          </div>
          <div className="user-list">
            {filteredProjectTypeList?.length > 0 ? (
              filteredProjectTypeList.map(emp => (
                <div className="user-line" key={emp.idProjectType}>
                  <Checkbox
                    icon={<img src={iconNotChecked} alt="Não selecionado" />}
                    checkedIcon={<img src={iconChecked} alt="Selecionado" />}
                    onChange={() => handleCheck(emp.idProjectType)}
                    checked={
                      projectTypesSelected.find(
                        x => x === emp.idProjectType
                      ) !== undefined
                    }
                  />
                  <span>{emp.name}</span>
                </div>
              ))
            ) : (
              <span className="centered">
                Nenhum tipo de projeto encontrado
              </span>
            )}
          </div>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default ProjectTypesModal;
