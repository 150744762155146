import React from "react";
import { Accordion } from "react-bootstrap";
import {
  HiPlusCircle,
  HiMinusCircle,
  HiExclamation,
  HiClock
} from "react-icons/hi";
import { StyledAccordionHeader } from "./styles";
import { Button } from "../../pages/Approvals/components/Button/style";

import { transformMinutesToHours } from "./../../../../utils/CalculateSumTime";

export const CustomAccordionReport = ({
  eventKey,
  title,
  icon,
  totalHours,
  qtdHoursNotAllocated,
  variant,
  children,
  handleAlocRequest,
  tab
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Accordion.Toggle
        onClick={() => setOpen(!open)}
        eventKey={eventKey}
        as={StyledAccordionHeader}
        variant={variant}
      >
        <div className="accordion-title">
          {icon && <span>{icon()}</span>}

          <div className="accordion-title">
            <span>{title}</span>
          </div>
        </div>

        <div className="toggle-icon">
          {tab === "AlocatorWithoutRequest" || tab === "AlocatorWithRequest" ? (
            <div className="approval-btn">
              <Button type="button" onClick={handleAlocRequest}>
                {tab === "AlocatorWithoutRequest" ? "Solicitar" : "Rejeitar"}
              </Button>
            </div>
          ) : (
            <></>
          )}

          {totalHours && (
            <span className="total-amount">
              {`Total de Horas: ${totalHours}`}
            </span>
          )}
          {(tab === "notAllocatedByAllocator" ||
            tab === "notLoadedByCoordinator") &&
            qtdHoursNotAllocated && (
              <span className="total-amount">
                {`Horas não alocadas: ${qtdHoursNotAllocated}`}
              </span>
            )}
          {open ? <HiMinusCircle /> : <HiPlusCircle />}
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={eventKey}>
        <>{children}</>
      </Accordion.Collapse>
    </>
  );
};
