import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { HiPlusCircle, HiClipboard, HiTrash } from "react-icons/hi";

export const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 90%;
    max-width: 1000px;
    margin: auto;
  }

  .modal-body {
    padding: 24px;
    margin-bottom: 24px;

    .modal-title {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      text-align: center;
      color: #4d4c4d;

      margin-bottom: 37px;
    }

    form {
      > div {
        padding: 0;
      }
    }
  }

  .btn-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & > button:first-child {
      margin-right: 24px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 100%;
  max-width: 145px;
  border-radius: 4px;
  border: 1px solid #4672c5;
  background: ${({ type }) => (type === "submit" ? "#4672C5" : "transparent")};

  font-family: "Cera Pro Regular", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${({ type }) => (type === "submit" ? "#fff" : "#4672C5")};

  box-shadow: ${({ type }) =>
    type === "submit"
      ? "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)"
      : "none"};

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Fields = styled.div`
  display: flex;
  margin-bottom: 24px;
  width: 100%;

  align-items: center;

  input[type="text"] {
    font-size: 12px;
    padding: 7px;
  }
`;

export const TitlePrj = styled.div`
  display: grid;
  grid-template-columns: 1fr auto; /* Define duas colunas, a primeira com 1 fração do espaço disponível e a segunda com tamanho automático */
  align-items: center;
  font-family: "Cera Pro Regular", sans-serif;
  color: #4d4c4d;
  width: 100%;
  height: 60px;

  .project {
    margin-left: 10px;
  }

  .time {
    margin-left: 37px;
    text-align: right;
  }

  h2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  h3 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  strong {
    font-weight: bold;
  }
`;

export const Period = styled.div`
  display: flex;
  width: 790px;
  justify-content: center;
  flex-direction: column;
  font-family: "Cera Pro Regular", sans-serif;
  color: #4d4c4d;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

export const StyledIconButton = styled.button`
  all: unset;
  width: min-content;

  :disabled {
    cursor: not-allowed;

    & > :first-child {
      pointer-events: none;
    }
  }
`;

export const StyledPlusIcon = styled(HiPlusCircle)`
  font-size: 22px;
  color: #4672c5;
  margin-top: -4px;
  margin-left: 5px;
  cursor: pointer;
`;

export const StyledPasteIcon = styled(HiClipboard)`
  font-size: 22px;
  color: #4672c5;
  margin-top: -4px;
  margin-left: 5px;
  cursor: pointer;
`;

export const StyledTrashIcon = styled(HiTrash)`
  font-size: 18px;
  color: #c54646;
  cursor: pointer;
`;
